<template>
  <div class="content">
    <Shimmer width="100%" height="600px" v-if="mentor.loading" />
    <section class="banner-mentor" v-if="mentor.items.image">
      <ImageResponsive
        :imageUrl="mentor.items.image"
        :imageUrlMobile="
          mentor.items.imageMobile
            ? mentor.items.imageMobile
            : mentor.items.image
        "
      />
      <div class="shadow"></div>
    </section>
    <section class="about-mentor">
      <div class="container">
        <div class="about-text">
          <Icons name="speaker" />
          <p class="text">TENTANG MENTOR</p>
        </div>
        <Shimmer width="40%" height="30px" v-if="mentor.loading" class="mb-2" />
        <h2 class="about-name">
          {{ mentor.items.name }}
        </h2>
        <Shimmer width="30%" height="20px" v-if="mentor.loading" class="mb-2" />
        <p class="about-profession">
          {{ mentor.items.profession }}
        </p>
        <Shimmer
          width="100%"
          height="20px"
          v-if="mentor.loading"
          class="mb-2"
        />
        <Shimmer width="80%" height="20px" v-if="mentor.loading" />
        <p class="about-description" v-html="mentor.items.about"></p>
      </div>
    </section>
    <section class="kenali-mentor-lainnya">
      <div class="container">
        <TitleSection title="KENALI MENTOR LAINNYA" class="title" />
        <div class="list-mentor-lainnya">
          <div
            v-swiper:mySwiperKenaliMentor="swiperOptionsKenaliMentor"
            @transition-start="onSwiperSlideChange('kenali-mentor')"
          >
            <div class="swiper-wrapper">
              <ShimmerKenaliMentor v-if="mentors.loading" />
              <div
                class="card-kenali-mentor swiper-slide"
                v-for="(item, index) in mentors.items"
                :key="index"
              >
                <ImageResponsive v-if="item.image" :imageUrl="item.image" />
                <div class="card-body-kenali-mentor">
                  <Link
                    :to="{
                      name: 'academy-mentor-slug',
                      params: {
                        slug: item.slug,
                      },
                    }"
                    class="name"
                    >{{ item.name }}
                  </Link>
                  <div class="profession">{{ item.profession }}</div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div
            class="shadow-arrow shadow-left"
            :class="arrow.kenaliMentor.prev ? 'hide' : ''"
          ></div>
          <div
            class="shadow-arrow shadow-right"
            :class="arrow.kenaliMentor.next ? 'hide' : ''"
          ></div>
          <Icons
            name="arrow-left"
            class="arrow prev prev-kenali-mentor"
            :class="arrow.kenaliMentor.prev ? 'hide' : ''"
          />
          <Icons
            name="arrow-right"
            class="arrow next next-kenali-mentor"
            :class="arrow.kenaliMentor.next ? 'hide' : ''"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import ImageResponsive from "@/components/ImageResponsive";
import Shimmer from "@/components/Shimmer";
import Icons from "@/components/Icons";
import TitleSection from "@/components/TitleSection";
import Link from "@/components/Link";
import ShimmerKenaliMentor from "@/components/shimmer/KenaliMentor";

export default {
  components: {
    Link,
    ShimmerKenaliMentor,
    ImageResponsive,
    Shimmer,
    Icons,
    TitleSection
  },
  async asyncData({ app, params, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Mentor Academy",
        desc: "Narasi Academy",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  data() {
    return {
      arrow: {
        kenaliMentor: {
          prev: true,
          next: false,
        },
      },
      swiperOptionsKenaliMentor: {
        freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          prevEl: ".prev-kenali-mentor",
          nextEl: ".next-kenali-mentor",
        },
        breakpoints: {
          1023: {
            slidesPerView: 5.2,
            spaceBetween: 15,
          },
          320: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      mentor: (state) => {
        return state.academies.academyDetailMentor;
      },
      mentors: (state) => {
        return state.academies.academyMentor;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("academies/getDetailMentor", {
        slug: this.$route.params.slug,
      });
      await this.$store.dispatch("academies/getMentor");
    },
    onSwiperSlideChange(params) {
      // this.totalSlide = swiper.slides.length;
      switch (params) {
        case "kenali-mentor":
          this.arrow.kenaliMentor.next = this.mySwiperKenaliMentor.isEnd;
          this.arrow.kenaliMentor.prev = this.mySwiperKenaliMentor.isBeginning;
          break;
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  @media only screen and (max-width: 1024px) {
    padding-bottom: 0 !important;
  }

  .banner-mentor {
    position: relative;

    .image {
      width: 100%;
      height: auto;
    }

    .shadow {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        hsla(0, 0%, 100%, 0),
        rgba(255, 255, 255)
      );
      position: absolute;
      bottom: -1%;
      left: 0;
      @media only screen and (max-width: 1024px) {
        height: 50%;
      }
    }
  }

  .about-mentor {
    position: relative;
    background: white;
    z-index: 1;

    .container {
      padding: 30px 0 80px;
      @media only screen and (max-width: 1024px) {
        padding: 10px 30px 32px;
      }

      .about-text {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 10px;
        }

        svg {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          @media only screen and (max-width: 1024px) {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }

        .text {
          font-size: 16px;
          font-weight: 500;
          color: #4a25aa;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      .about-name {
        font-size: 32px;
        font-weight: 500;
        @media only screen and (max-width: 1024px) {
          font-size: 20px;
          margin-bottom: 8px;
        }
      }

      .about-profession {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 25px;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
          margin-bottom: 16px;
        }
      }

      .about-description {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }

  .kenali-mentor-lainnya {
    .container {
      padding-top: 50px;
      padding-bottom: 80px;
      @media only screen and (max-width: 1024px) {
        padding-top: 32px;
        padding-bottom: 48px;
      }

      .title {
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }
      }

      .list-mentor-lainnya {
        position: relative;

        .shadow-arrow {
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          padding: 0;
          background: rgb(0, 0, 0);
          z-index: 2;
          opacity: 1;
          transition: ease-in-out 0.5s;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.shadow-left {
            left: 0;
            background: linear-gradient(
              to left,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.shadow-right {
            right: 0;
            background: linear-gradient(
              to right,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.hide {
            opacity: 0;
            z-index: 0;
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          width: auto;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }

          &.hide {
            display: none;
          }
        }

        .swiper-container {
          @media only screen and (max-width: 1024px) {
            padding: 20px;
            margin: -20px;
          }
        }

        .card-kenali-mentor {
          flex-direction: column;
          align-items: center;
          width: 250px;
          height: fit-content;
          border-radius: 10px;
          overflow: hidden;
          background: white;

          .image {
            height: 130px;
            @media only screen and (max-width: 1024px) {
              height: 85px;
            }
          }

          .card-body-kenali-mentor {
            padding: 25px 15px;
            text-align: center;
            @media only screen and (max-width: 1024px) {
              padding: 20px 10px;
            }

            .name {
              font-size: 16px;
              font-weight: 500;
              color: black;
              margin-bottom: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
              }

              &:hover {
                color: black !important;
              }
            }

            .profession {
              font-size: 12px;
              font-weight: 400;
              @media only screen and (max-width: 1024px) {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
