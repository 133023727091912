<template>
  <div class="content">
    <ModalShare @registeredModal="modalShare = $event" />
    <ModalLogin
      :redirect="$route.path"
      @registeredModal="modalLogin = $event"
    />
    <div class="title-wrapper">
      <h1 class="title">{{ GetPaymentStatus }}</h1>
      <p class="desc">
        {{ GetPaymentStatusDesc }}
      </p>
    </div>
    <div class="info-wrapper">
      <Icons name="info" />
      <p class="text">
        Nama yang terdaftar pada akun Narasi.tv akan dicantumkan dalam
        sertifikat, pastikan sudah sesuai dengan identitas resmi
      </p>
    </div>
    <section class="card-transaction">
      <div class="container">
        <div class="header-card">
          <h2 class="title">Pesanan Anda</h2>
          <div
            class="expired"
            v-if="
              academy.paymentStatus == 'PENDING' ||
              academy.paymentStatus == 'EXPIRED'
            "
          >
            <p class="text">BATAS PEMBAYARAN</p>
            <h2 class="date">
              {{
                this.$moment(academy.expiredDate).format(
                  "dddd Do MMMM YYYY, H:mm"
                )
              }}
            </h2>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="thumbnail">
            <ImageResponsive
              :imageUrl="
                academy && academy.thumbnail
                  ? academy.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :fromUrl="true"
            />
          </div>
          <div class="copy-wrapper">
            <div
              class="payment-status"
              :class="
                academy.paymentStatus == 'SUCCESS'
                  ? 'success'
                  : academy.paymentStatus == 'PENDING'
                  ? 'pending'
                  : 'expired'
              "
            >
              <p class="text">
                {{
                  academy.paymentStatus == "SUCCESS"
                    ? "PAID"
                    : academy.paymentStatus == "PENDING"
                    ? "PENDING"
                    : "EXPIRED"
                }}
              </p>
            </div>
            <h1 class="title-class">{{ academy.title }}</h1>
            <div class="box location">
              <Icons name="location" color="#616161" />
              <p class="text">{{ academy.location }}</p>
            </div>
            <div class="box tipe-kelas">
              <Icons :name="checkClassLevel" color="#616161" />
              <p class="text">{{ academy.classLevel }}</p>
            </div>
            <!-- <div class="box organizer">
              <Icons name="organizer" color="#616161" />
              <p class="text">{{ academy.location }}</p>
            </div> -->
          </div>
        </div>
        <div class="footer-card">
          <div
            class="price-wrapper"
            v-if="
              academy.discountPrice !== 0 ||
              academy.paymentStatus == 'PENDING' ||
              academy.paymentStatus == 'EXPIRED'
            "
          >
            <p class="text">TOTAL BAYAR</p>
            <h2 class="price">{{ changeToRupiah(academy.discountPrice) }}</h2>
          </div>
          <div class="card-btn">
            <span
              class="btn-masuk"
              :class="[
                {
                  disable:
                    academy.paymentStatus == 'EXPIRED' ||
                    academy.paymentStatus == null,
                },
                academy.paymentStatus == 'PENDING'
                  ? 'btn-tag-bayar-sekarang'
                  : academy.paymentStatus == 'SUCCESS'
                  ? 'btn-tag-masuk-kelas'
                  : 'btn-tag-daftar-kelas',
              ]"
              @click="beliKelas()"
              >{{
                academy.paymentStatus == "SUCCESS"
                  ? "MASUK KELAS"
                  : "BAYAR SEKARANG"
              }}</span
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import CardEleven from "/components/cards/CardEleven";
import ModalLogin from "/components/modal/Login.vue";
import ModalShare from "/components/modal/Share";
import Icons from "/components/Icons";
import ImageResponsive from "/components/ImageResponsive";

export default {
  middleware: "auth",
  async asyncData({ app, params, store, route, error }) {
    try {
      store.commit("academies/SET_DETAIL_CLASS", {});
      store.commit("config/setLoading", true);
      const response = await app.$axios
        .$get(`${config.ACADEMY_SERVICE_API}class/${params.slug}`)
        .catch((e) => {
          error({ statusCode: 404, message: "Page not found" });
        });

      if (!response.data.paymentStatus) {
        error({ statusCode: 500, message: "Page not found" });
      }

      store.commit("seo/SET_SEO", {
        title: response.data.title,
        desc: response.data.description,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      store.commit("academies/SET_DETAIL_CLASS", response.data);
      store.commit("config/setLoading", false);
      return { response };
    } catch (err) {
      console.log(err);
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ImageResponsive,
    Icons,
    CardEleven,
    ModalLogin,
    ModalShare,
  },
  data() {
    return {
      modalLogin: false,
      modalShare: false,
      limit: 300,
      priceAfterDiscount: 0,
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      academy: (state) => {
        return state.academies.academyDetailClass.items;
      },
    }),
    // academy() {
    //   return this.response.data;
    // },
    GetPaymentStatus() {
      if (this.academy.paymentStatus === "SUCCESS") {
        return "Pendaftaran berhasil diproses!";
      } else if (this.academy.paymentStatus === "PENDING") {
        return "Silakan selesaikan pembayaran Anda";
      } else {
        return "Maaf, waktu pembayaran telah habis";
      }
    },
    GetPaymentStatusDesc() {
      if (this.academy.paymentStatus === "SUCCESS") {
        return "Silahkan periksa email Anda atau masuk ke halaman kelas";
      } else if (this.academy.paymentStatus === "PENDING") {
        return "Mohon selesaikan pembayaran untuk pesanan Anda sebelum:";
      } else {
        return "Transaksi dibatalkan secara otomatis oleh sistem";
      }
    },
    checkClassLevel() {
      if (this.academy.classLevel === "BASIC") {
        return "basic";
      } else if (this.academy.classLevel === "INTERMEDIATE") {
        return "intermediate";
      } else {
        return "advance";
      }
    },
  },
  methods: {
    // async initData() {
    // await this.$store.dispatch("academies/getDetailClass", {
    //   slug: this.$route.params.slug,
    // });
    // },

    beliKelas() {
      if (!this.$store.state.auth.loggedIn) {
        return this.modalLogin.show();
      } else if (this.academy.paymentStatus === "PENDING") {
        window.open(this.academy.classUrl);
      } else if (this.academy.paymentStatus === "SUCCESS") {
        setTimeout(() => {
          window.open(`https://course.narasi.tv/sign/${this.$route.params.slug}?token=${this.$axios.defaults.headers.common['Authorization'].replace('Bearer ', '')}`)
        })
      } else {
        return this.$toast.error("Oops, ada kesalahan");
      }
    },
    changeToRupiah(data) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      })
        .format(data)
        .split(",")[0]
        .replace(/\s/g, "");
      // let bilangan = data;
      // let reverse = bilangan.toString().split("").reverse().join(""),
      //   ribuan = reverse.match(/\d{1,3}/g);
      // ribuan = ribuan.join(".").split("").reverse().join("");
      // return "Rp. " + ribuan;
    },
  },
  // mounted() {
  //   this.initData();
  // },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 48px 0;
  @media only screen and (max-width: 1024px) {
    padding: 32px 0;
  }
  #modal-share {
    .modal-header {
      padding: 30px 30px 20px;
      border: none;

      .modal-title {
        font-size: 24px;
        font-weight: 500;
        color: #4a25aa;
      }

      .close {
        cursor: pointer;
        opacity: 1;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 30px;

      .sosmed-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        svg {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }

        .sosmed {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
  }
  .title-wrapper {
    text-align: center;
    .title {
      font-size: 32px;
      font-weight: 600;
      color: #051c2c;
      margin-bottom: 16px;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }
    .desc {
      font-size: 16px;
      color: #757575;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 18px;
    background: #e0e0e0;
    width: fit-content;
    margin: 32px auto;
    border-radius: 6px;
    @media only screen and (max-width: 1024px) {
      margin: 24px auto;
      width: 90%;
      align-items: flex-start;
    }
    svg {
      width: 14px;
      height: 14px;
      margin-right: 12px;
      @media only screen and (max-width: 1024px) {
        width: 30px;
        height: 20px;
      }
    }
    .text {
      font-size: 12px;
      color: #757575;
      margin: 0;
    }
  }
  .card-transaction {
    @media only screen and (max-width: 1024px) {
      padding: 0 20px;
    }
    .container {
      background: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      padding: 0;
      @media only screen and (max-width: 1024px) {
        border-radius: 6px;
      }
      .header-card {
        padding: 32px;
        background: #fafafa;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          padding: 24px;
        }
        .title {
          font-size: 24px;
          font-weight: 500;
          color: #0c0b0d;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
        .expired {
          .text {
            font-size: 12px;
            font-weight: 500;
            color: #9e9e9e;
            margin-bottom: 8px;
            text-align: right;
            @media only screen and (max-width: 1024px) {
              font-size: 9px;
              margin-bottom: 4px;
            }
          }
          .date {
            font-size: 24px;
            font-weight: 500;
            color: #e73638;
            margin: 0;
            text-align: right;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
      }
      .card-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 32px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          padding: 24px;
        }
        .thumbnail {
          width: 30%;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            margin-bottom: 24px;
          }
        }
        .copy-wrapper {
          width: 70%;
          padding-left: 30px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            padding-left: 0;
          }
          .payment-status {
            background: #43936c;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            margin-bottom: 16px;
            @media only screen and (max-width: 1024px) {
              margin-bottom: 10px;
            }
            &.expired {
              background: #e73638;
            }
            &.pending {
              background: #ffe900;
              .text {
                color: #051c2c;
              }
            }
            .text {
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
              padding: 4px 10px;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
                padding: 4px 8px;
              }
            }
          }
          .title-class {
            font-size: 24px;
            font-weight: 500;
            color: #0c0b0d;
            margin-bottom: 16px;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              margin-bottom: 10px;
            }
          }
          .box {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px;
            svg {
              width: 20px;
              height: auto;
              @media only screen and (max-width: 1024px) {
                width: 16px;
              }
            }
            .text {
              font-size: 12px;
              color: #051c2c;
              margin: 0;
              padding-left: 10px;
            }
          }
        }
      }
      .footer-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 32px;
        border-top: 1px solid #e0e0e0;
        @media only screen and (max-width: 1024px) {
          justify-content: space-between;
          padding: 12px 18px;
        }
        .price-wrapper {
          margin-right: 30px;
          @media only screen and (max-width: 1024px) {
            margin: 0;
          }
          .text {
            @media only screen and (max-width: 1024px) {
              font-size: 10px;
              margin-bottom: 4px;
            }
          }
          .price {
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
        .card-btn {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .btn-masuk {
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
            white-space: nowrap;
            background: #4a25aa;
            padding: 14px 20px;
            border-radius: 4px;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
            &.disable {
              pointer-events: none;
              background: #e0e0e0;
              color: #9e9e9e;
            }
            &:hover {
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }
}
</style>
