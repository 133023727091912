<template>
  <div class="content" :class="{ setMargin: $route.name === 'academy-slug' }">
    <div class="breadcrumbs">
      <div class="container">
        <Link
          :to="{
            name: 'academy',
          }"
          >Home
        </Link>
        <Icons name="arrow-right" />
        <Link
          :to="{
            name: 'academy-slug',
          }"
          class="Link-exact-active"
          >{{ academy.items.title }}
        </Link>
      </div>
    </div>
    <div class="container">
      <div class="watch">
        <div class="video-wrapper">
          <Shimmer width="100%" height="500px" v-if="academy.loading" />
          <iframe
            v-if="!academy.loading"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/0hO_xJuUVPI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="copy-wrapper is-desktop">
          <Shimmer
            width="60%"
            height="30px"
            v-if="academy.loading"
            class="mb-2"
          />
          <div class="video-title">
            {{ academy.items.title }}
          </div>
          <Shimmer
            width="100%"
            height="20px"
            v-if="academy.loading"
            class="mb-2"
          />
          <Shimmer width="50%" height="20px" v-if="academy.loading" />

          <div class="video-desc-wrapper">
            <p
              v-if="academy.items.description"
              class="video-desc"
              v-html="setDesc(academy.items.description)"
            ></p>
            <a @click="openDesc()" class="read-more">{{ readMore.label }}</a>
          </div>
        </div>
      </div>
      <div class="playlist">
        <ShimmerTitlePlaylistVideo v-if="academy.loading" />
        <div class="playlist-title" v-if="!academy.loading">
          <h2 class="title">{{ academy.items.title }}</h2>
          <p class="total-video" v-if="academy.items.playlistVideo">
            {{ activeVideo }}/{{ academy.items.playlistVideo.length }}
            Video
          </p>
        </div>
        <div class="video-desc-wrapper">
          <p
            v-if="academy.items.description"
            class="video-desc-mobile"
            v-html="setDesc(academy.items.description)"
          ></p>
          <a @click="openDesc()" class="read-more">{{ readMore.label }}</a>
        </div>
        <div class="playlist-nav">
          <Icons name="prev-video" @click.native="goTo('prev')" />
          <Icons name="next-video" @click.native="goTo('next')" />
        </div>
        <div class="playlist-video">
          <div v-show="top" class="shadow-playlist top"></div>
          <div v-show="bottom" class="shadow-playlist bottom"></div>
          <div class="playlist-video-content" @scroll="onScroll">
            <ShimmerVideoAcademy v-if="academy.loading" />
            <div
              class="card-video-wrapper"
              v-for="(item, index) in academy.items.playlistVideo"
              :key="index"
              :class="$route.query.video === item.slug ? 'active' : ''"
            >
              <div class="card-video">
                <div class="card-video-thumbnail">
                  <ImageResponsive imageUrl="academy/thumbnail.png" />

                  <div class="ticket">
                    <Icons name="ticket" />
                    <div>Gratis</div>
                  </div>
                </div>
                <div class="card-video-body">
                  <Link
                    :to="{
                      name: 'academy-slug',
                      params: {
                        slug: academy.items.slug,
                      },
                      query: {
                        video: item.slug,
                      },
                    }"
                    class="card-video-title"
                    @click.native="setActiveVideo(index)"
                  >
                    {{ item.title }}
                  </Link>
                  <div class="card-video-duration">
                    <Icons
                      name="circle-play"
                      :color="
                        $route.query.video ===
                        academy.items.playlistVideo[index].slug
                          ? '#fff'
                          : ''
                      "
                    />
                    <div class="duration">09:00 Menit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import Shimmer from "@/components/Shimmer";
import ImageResponsive from "@/components/ImageResponsive";
import ShimmerTitlePlaylistVideo from "@/components/shimmer/TitlePlaylistVideo";
import ShimmerVideoAcademy from "@/components/shimmer/VideoAcademy";

export default {
  components: {
    ImageResponsive,
    ShimmerVideoAcademy,
    ShimmerTitlePlaylistVideo,
    Link,
    Icons,
    Shimmer
  },
  async asyncData({ error, store, route }) {
    // error({ statusCode: 404, message: "Page not found" });
    try {
      store.commit("seo/SET_SEO", {
        title: "Lanjutkan Kelas Academy",
        desc: "Narasi Academy",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {}
  },
  data() {
    return {
      top: false,
      bottom: true,
      activeVideo: null,
      indexVideo: null,
      readMore: {
        status: false,
        label: "Read More",
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      academy: (state) => {
        return state.academies.academyDetail;
      },
    }),
  },
  watch: {
    "$route.query.video": {
      handler: function (video) {
        this.videoActiveNumber(video);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setDesc(val) {
      let data = "";
      if (this.readMore.status) {
        data = val;
      } else {
        data = val.substr(0, 200) + "...";
      }
      return data;
    },
    openDesc() {
      this.readMore.status = !this.readMore.status;
      if (this.readMore.status) {
        this.readMore.label = "Read Less";
      } else {
        this.readMore.label = "Read More";
      }
    },
    videoActiveNumber(video) {
      for (const x in this.academy.items.playlistVideo) {
        if (video === this.academy.items.playlistVideo[x].slug) {
          let y = parseInt(x);
          this.activeVideo = y + 1;
        }
      }
    },
    setActiveVideo(i) {
      this.indexVideo = i;
    },
    goTo(params) {
      let x = this.activeVideo - 1;
      switch (params) {
        case "prev":
          window.location.href = this.$router.resolve({
            name: "academy-slug",
            params: {
              slug: this.academy.items.slug,
            },
            query: {
              video:
                x === 0
                  ? this.academy.items.playlistVideo[0].slug
                  : this.academy.items.playlistVideo[x - 1].slug,
            },
          }).href;
          break;
        case "next":
          window.location.href = this.$router.resolve({
            name: "academy-slug",
            params: {
              slug: this.academy.items.slug,
            },
            query: {
              video:
                x === this.academy.items.playlistVideo.length - 1
                  ? this.academy.items.playlistVideo[
                      this.academy.items.playlistVideo.length - 1
                    ].slug
                  : this.academy.items.playlistVideo[x + 1].slug,
            },
          }).href;
          break;
      }
    },
    async initData() {
      await this.$store.dispatch("academies/getDetail", {
        slug: this.$route.params.slug,
      });
    },
    onScroll(e) {
      // console.log(e.target.scrollTop, e);
      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
        this.top = true;
        this.bottom = false;
      } else if (e.target.scrollTop === 0) {
        this.top = false;
        this.bottom = true;
      } else {
        this.top = true;
        this.bottom = true;
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  @media only screen and (max-width: 1024px) {
    padding-bottom: 0 !important;
  }

  &.setMargin {
    margin-top: 58px;
    @media only screen and (max-width: 1024px) {
      margin-top: 56px;
    }
  }

  .breadcrumbs {
    .container {
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media only screen and (max-width: 1024px) {
        flex-direction: row;
        padding: 12px 20px;
      }

      a {
        font-size: 14px;
        font-weight: 400;
        color: #616161;
        @media only screen and (max-width: 1024px) {
          max-width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.Link-exact-active {
          color: #0c0b0d;
          font-weight: 500;
        }
      }

      svg {
        width: 20px;
        height: auto;
        margin: 0 10px;
      }
    }
  }

  .container {
    padding: 32px 0 48px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 0;
    }

    .watch {
      width: 68%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }

      .video-wrapper {
        width: 100%;
        height: 500px;
        @media only screen and (max-width: 1024px) {
          height: 220px;
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      .copy-wrapper {
        margin-top: 24px;
        @media only screen and (max-width: 1024px) {
          margin: 20px 0;
          padding: 0 30px;
        }

        .video-title {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 4px;
          @media only screen and (max-width: 1024px) {
            font-size: 20px;
          }
        }

        .video-desc-wrapper {
          display: block;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          .video-desc {
            font-size: 14px;
            font-weight: 400;
            display: inline;
            margin: 0;
          }

          .read-more {
            font-size: 14px;
            font-weight: 500;
            color: black;
            cursor: pointer;
          }
        }
      }
    }

    .playlist {
      width: 30%;
      background: white;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }

      .playlist-title {
        display: flex;
        padding: 16px 16px 12px;
        margin-bottom: 5px;
        @media only screen and (max-width: 1024px) {
          padding: 30px 30px 0;
          margin-bottom: 10px;
        }

        .title {
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
          width: 75%;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }

        .total-video {
          font-size: 16px;
          font-weight: 400;
          color: #616161;
          width: 25%;
          text-align: right;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      .video-desc-wrapper {
        display: none;
        @media only screen and (max-width: 1024px) {
          display: block;
          margin-bottom: 20px;
          padding: 0 30px;
        }

        .video-desc-mobile {
          @media only screen and (max-width: 1024px) {
            display: inline;
            font-size: 12px;
          }
        }

        .read-more {
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            font-weight: 500;
            color: black;
            cursor: pointer;
          }
        }
      }

      .playlist-nav {
        display: flex;
        padding: 0 20px;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          padding: 0 30px;
        }

        svg {
          width: 15px;
          height: 15px;
          margin-right: 15px;
          cursor: pointer;
        }
      }

      .playlist-video {
        position: relative;

        .shadow-playlist {
          position: absolute;
          left: 0;
          width: 100%;
          height: 20px;
          z-index: 2;

          &.top {
            top: 0;
            box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.3) inset;
            -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.3) inset;
            -moz-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.3) inset;
          }

          &.bottom {
            bottom: 0;
            box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.3) inset;
            -webkit-box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.3) inset;
            -moz-box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.3) inset;
          }
        }

        .playlist-video-content {
          height: 500px;
          overflow-y: auto;

          &::-webkit-scrollbar-track {
            //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            //   background-color: #f5f5f5;
            background-color: #fff;
          }

          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
            margin-left: 5px;
          }

          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #c4c4c4;
          }

          .card-video-wrapper {
            padding: 20px 0;
            border-bottom: 1px solid #dddddd;

            &:last-child {
              border: none;
            }

            .card-video {
              display: flex;
              height: 100px;
              padding: 0 20px;
              @media only screen and (max-width: 1024px) {
                padding: 0 30px;
              }

              .card-video-thumbnail {
                position: relative;
                width: 45%;

                .image {
                  height: 100%;
                  border-radius: 8px;
                }

                .ticket {
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  padding: 4px 8px;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  color: white;
                  background: rgb(182, 15, 127);
                  background: linear-gradient(
                    90deg,
                    rgba(182, 15, 127, 1) 0%,
                    rgba(74, 37, 170, 1) 100%
                  );
                  border-radius: 5px;

                  svg {
                    margin-right: 5px;
                  }
                }
              }

              .card-video-body {
                width: 55%;
                padding: 0 17px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .card-video-title {
                  font-size: 14px;
                  font-weight: 500;
                  color: black;
                  margin-bottom: 13px;
                  display: block;

                  &:hover {
                    color: black !important;
                  }
                }

                .card-video-duration {
                  display: flex;
                  align-items: center;

                  svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                  }

                  .duration {
                    font-size: 12px;
                    font-weight: 400;
                  }
                }
              }
            }

            &.active {
              background: #4a25aa;
              color: white !important;

              .card-video {
                .card-video-body {
                  .card-video-title {
                    color: white !important;

                    &:hover {
                      color: white !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
