<template>
  <div class="content">
    <div v-if="karya_academy.items.image">
      <ModalImage
        v-show="showModal"
        @close-modal="showModal = false"
        :imageData="karya_academy.items.image"
      />
    </div>
    <div class="container">
      <ShimmerArticleLinimasa v-if="karya_academy.loading" />
      <div
        class="article-wrapper"
        v-if="!karya_academy.loading && karya_academy.items"
      >
        <div class="img-wrapper" v-if="karya_academy.items.image">
          <ImageResponsive
            :imageUrl="karya_academy.items.image"
            class="card-img-top"
            alt=""
          />
        </div>
        <div class="description">
          <div class="academics">
            <p class="card-name">
              {{ karya_academy.items.name }}
            </p>
            <span class="px-2">&bull;</span>
            <p class="card-batch">
              Academy Batch {{ karya_academy.items.batch }}
            </p>
          </div>
          <h2 class="card-title">
            {{ karya_academy.items.title }}
          </h2>
          <p class="card-desc">
            {{ karya_academy.items.description }}
          </p>
          <button
            class="btn btn-lihat-foto fs-14 px-4"
            data-toggle="modal"
            data-target="#exampleModal"
            @click="showModal = true"
          >
            LIHAT FOTO
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import ModalImage from "/components/academy/ModalImage";
import ShimmerArticleLinimasa from "/components/shimmer/ArticleLinimasa";
import ImageResponsive from "/components/ImageResponsive";

export default {
  async asyncData({ app, params, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Karya Academy",
        desc: "Narasi Academy",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    ImageResponsive,
    ShimmerArticleLinimasa,
    ModalImage,
  },
  data() {
    return {
      showModal: false,
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      karya_academy: (state) => {
        return state.academies.academyDetailKarya;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("academies/getDetailKaryaAcademy", {
        slug: this.$route.params.slug,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  .container {
    padding: 32px 0 48px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 30px;
    }

    .article-wrapper {
      display: flex;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
      }

      .img-wrapper {
        width: 35%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 24px;
        }
      }

      .description {
        width: 65%;
        padding: 0 50px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding: 0;
        }

        .academics {
          display: flex;
          margin-bottom: 15px;
          color: #4a25aa;

          .card-name,
          .card-batch {
            font-size: 14px;
            font-weight: 400;
            color: #616161;
            margin: 0;
          }

          .card-title {
            font-size: 24px;
          }

          .card-desc {
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .category-wrapper {
      }

      .card-title {
        font-size: 24px;
        font-weight: 500;
        color: #051c2c;
        margin-bottom: 15px;
        @media only screen and (max-width: 1024px) {
          line-height: 28px;
        }

        &:hover {
          color: #051c2c;
        }
      }

      .card-desc {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 28px;
        text-align: justify;
      }

      .btn-lihat-foto {
        color: #4a25aa;
        font-weight: 700;
        border: 1px solid #4a25aa;
        border-radius: 4px;

        &:hover {
          background: #4a25aa;
          color: white;
          border: 1px solid white;
        }
      }
    }
  }
}
</style>
