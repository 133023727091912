<template>
  <div class="content">
    <div class="container">
      <h1 class="faq-title">Kamu masih punya pertanyaan?</h1>
      <div class="list-faq">
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div
            class="accordion-item"
            v-for="(faq, index) in FAQ.items"
            :key="'faq-' + index"
          >
            <h2
              class="accordion-header"
              :id="'panelsStayOpen-headingOne' + index"
            >
              <button
                class="accordion-button"
                :class="index == 0 ? '' : 'collapsed'"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#panelsStayOpen-collapseOne' + index"
                :aria-expanded="index == 0 ? true : false"
                :aria-controls="'panelsStayOpen-collapseOne' + index"
              >
                <h2 class="title">{{ faq.title }}</h2>
              </button>
            </h2>
            <div
              :id="'panelsStayOpen-collapseOne' + index"
              class="accordion-collapse collapse"
              :class="index == 0 ? 'show' : ''"
              :aria-labelledby="'panelsStayOpen-headingOne' + index"
            >
              <div class="accordion-body">
                <div class="desc-wrapper">
                  <p class="desc" v-html="faq.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";

export default {
  async asyncData({ error, store, route }) {
    // error({ statusCode: 404, message: "Page not found" });
    try {
      store.commit("seo/SET_SEO", {
        title: "Frequently Asked Questions Narasi Academy",
        desc: "All about Narasi Academy",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  data() {
    return {};
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      FAQ: (state) => {
        return state.academies.academyFAQ;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("academies/getFAQ", 999);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 48px 0;
  .container {
    .faq-title {
      font-size: 32px;
      text-align: center;
      margin-bottom: 24px;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
    .list-faq {
      margin-bottom: 32px;
      .accordion {
        .accordion-item {
          margin-bottom: 12px;
          border-radius: 6px;
          overflow: hidden;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 12px;
          }
          &:last-child {
            margin: 0;
          }
          .accordion-header {
            .accordion-button {
              background: #ffffff;
              box-shadow: none;
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L10 10.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289Z' fill='%23424242'/%3E%3C/svg%3E%0A");
                background-size: 25px;
                width: 25px;
                height: 25px;
                @media only screen and (max-width: 1024px) {
                  background-size: 20px;
                  width: 20px;
                  height: 20px;
                }
              }
              .title {
                font-size: 20px;
                font-weight: 600;
                color: #616161;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  font-size: 16px;
                }
              }
            }
          }
          .accordion-collapse {
            .accordion-body {
              @media only screen and (max-width: 1024px) {
                padding-top: 0;
              }
              .desc-wrapper {
                .desc {
                  font-size: 18px;
                  color: #616161;
                  @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .goto-detail-faq {
      .title {
        font-size: 32px;
        color: #0c0b0d;
        text-align: center;
        margin-bottom: 16px;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
          margin-bottom: 8px;
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-see-more {
          font-size: 16px;
          font-weight: 500;
          color: #4a25aa;
          text-decoration: underline !important;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
          &:hover {
            color: #4a25aa !important;
          }
        }
        svg {
          width: 12px;
          height: auto;
          margin-left: 12px;
          @media only screen and (max-width: 1024px) {
            width: 8px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
