var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('section',{staticClass:"class-category"},[_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperClassCategory",value:(_vm.swiperOptionsClassCategory),expression:"swiperOptionsClassCategory",arg:"mySwiperClassCategory"}],staticClass:"py-2"},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.kelas.loading)?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_vm._l((_vm.kelas.items),function(item){return _c('Link',{key:item.id,staticClass:"swiper-slide category",attrs:{"to":{
              name: 'academy-kelas',
              query: {
                type: item.slug,
              },
            }}},[_vm._v(_vm._s(item.name)+"\n          ")])})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})])])]),_vm._v(" "),_c('section',{staticClass:"list-card-class-category"},[_c('div',{staticClass:"container"},[(_vm.academies.loading)?_c('ShimmerCardSeeAll'):_vm._e(),_vm._v(" "),_vm._l((_vm.academies.items),function(card){return _c('CardFourteen',{key:card.id,staticClass:"card-class-category",attrs:{"image":card.image,"ticket":card.discount !== 100 ? 'Berbayar' : 'Gratis',"category":card.category,"title":card.title,"slug":card.slug,"description":card.description,"datetime":card.date,"price":card.price,"discount":card.discount,"to":{
          name: 'academy-detail-slug',
          params: {
            slug: card.slug,
          },
          query: {
            'tipe-kelas': card.classType.slug,
          },
        },"classType":card.classType}})})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }