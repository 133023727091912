var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('ModalShare',{on:{"registeredModal":function($event){_vm.modalShare = $event}}}),_vm._v(" "),_c('ModalLogin',{attrs:{"redirect":_vm.$route.path},on:{"registeredModal":function($event){_vm.modalLogin = $event}}}),_vm._v(" "),_c('div',{staticClass:"title-wrapper"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.GetPaymentStatus))]),_vm._v(" "),_c('p',{staticClass:"desc"},[_vm._v("\n      "+_vm._s(_vm.GetPaymentStatusDesc)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"info-wrapper"},[_c('Icons',{attrs:{"name":"info"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("\n      Nama yang terdaftar pada akun Narasi.tv akan dicantumkan dalam\n      sertifikat, pastikan sudah sesuai dengan identitas resmi\n    ")])],1),_vm._v(" "),_c('section',{staticClass:"card-transaction"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-card"},[_c('h2',{staticClass:"title"},[_vm._v("Pesanan Anda")]),_vm._v(" "),(
            _vm.academy.paymentStatus == 'PENDING' ||
            _vm.academy.paymentStatus == 'EXPIRED'
          )?_c('div',{staticClass:"expired"},[_c('p',{staticClass:"text"},[_vm._v("BATAS PEMBAYARAN")]),_vm._v(" "),_c('h2',{staticClass:"date"},[_vm._v("\n            "+_vm._s(this.$moment(_vm.academy.expiredDate).format(
                "dddd Do MMMM YYYY, H:mm"
              ))+"\n          ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card-wrapper"},[_c('div',{staticClass:"thumbnail"},[_c('ImageResponsive',{attrs:{"imageUrl":_vm.academy && _vm.academy.thumbnail
                ? _vm.academy.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png',"fromUrl":true}})],1),_vm._v(" "),_c('div',{staticClass:"copy-wrapper"},[_c('div',{staticClass:"payment-status",class:_vm.academy.paymentStatus == 'SUCCESS'
                ? 'success'
                : _vm.academy.paymentStatus == 'PENDING'
                ? 'pending'
                : 'expired'},[_c('p',{staticClass:"text"},[_vm._v("\n              "+_vm._s(_vm.academy.paymentStatus == "SUCCESS"
                  ? "PAID"
                  : _vm.academy.paymentStatus == "PENDING"
                  ? "PENDING"
                  : "EXPIRED")+"\n            ")])]),_vm._v(" "),_c('h1',{staticClass:"title-class"},[_vm._v(_vm._s(_vm.academy.title))]),_vm._v(" "),_c('div',{staticClass:"box location"},[_c('Icons',{attrs:{"name":"location","color":"#616161"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.academy.location))])],1),_vm._v(" "),_c('div',{staticClass:"box tipe-kelas"},[_c('Icons',{attrs:{"name":_vm.checkClassLevel,"color":"#616161"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.academy.classLevel))])],1)])]),_vm._v(" "),_c('div',{staticClass:"footer-card"},[(
            _vm.academy.discountPrice !== 0 ||
            _vm.academy.paymentStatus == 'PENDING' ||
            _vm.academy.paymentStatus == 'EXPIRED'
          )?_c('div',{staticClass:"price-wrapper"},[_c('p',{staticClass:"text"},[_vm._v("TOTAL BAYAR")]),_vm._v(" "),_c('h2',{staticClass:"price"},[_vm._v(_vm._s(_vm.changeToRupiah(_vm.academy.discountPrice)))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-btn"},[_c('span',{staticClass:"btn-masuk",class:[
              {
                disable:
                  _vm.academy.paymentStatus == 'EXPIRED' ||
                  _vm.academy.paymentStatus == null,
              },
              _vm.academy.paymentStatus == 'PENDING'
                ? 'btn-tag-bayar-sekarang'
                : _vm.academy.paymentStatus == 'SUCCESS'
                ? 'btn-tag-masuk-kelas'
                : 'btn-tag-daftar-kelas',
            ],on:{"click":function($event){return _vm.beliKelas()}}},[_vm._v(_vm._s(_vm.academy.paymentStatus == "SUCCESS"
                ? "MASUK KELAS"
                : "BAYAR SEKARANG"))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }