<template>
  <div class="content-main">
    <ModalShare @registeredModal="modalShare = $event" />
    <ModalComp
      @registeredModal="modalInfo1 = $event"
      desc="Maaf, untuk sementara Anda tidak dapat melakukan pembelian kelas. Pembelian akan dibuka kembali tanggal 9 Januari 2023."
    />
    <ModalComp
      @registeredModal="modalInfo2 = $event"
      desc="Maaf, saat ini kami sedang melakukan peningkatan performa layanan sehingga untuk sementara kelas tidak dapat diakses. Akses kelas akan dibuka kembali tanggal 9 Januari 2023."
    />
    <ModalLogin
      :redirect="$route.path"
      @registeredModal="modalLogin = $event"
    />
    <ModalVoucher
      v-model.trim="voucherCode"
      service="Academy"
      :refId="response.data.id"
      @registeredModal="modalVoucher = $event"
    >
      <div class="price-modal">
        <div class="d-flex justify-content-between align-items-center">
          <span class="price">Total Harga</span>
          <span class="price">{{
            changeToRupiah(response.data.discountPrice)
          }}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="price">Potongan Harga</span>
          <span class="price" v-if="$store.state.voucher.data.detail"
            >-{{
              changeToRupiah($store.state.voucher.data.detail.amountDiscount)
            }}</span
          >
        </div>
      </div>
      <div class="price-modal-total">
        <div class="d-flex justify-content-between align-items-center">
          <span class="price-total">TOTAL BAYAR</span>
          <span
            class="price-total-format"
            v-if="$store.state.voucher.data.detail"
            >{{
              changeToRupiah(
                response.data.discountPrice -
                  $store.state.voucher.data.detail.amountDiscount
              )
            }}</span
          >
          <span class="price-total-format" v-else>{{
            changeToRupiah(response.data.discountPrice)
          }}</span>
        </div>
      </div>
      <button class="btn-voucher btn-tag-bayar-kelas" @click="checkoutClass()">
        BAYAR KELAS
      </button>
    </ModalVoucher>
    <section class="academy-card">
      <div class="card-thumbnail">
        <ImageResponsive
          :imageUrl="
            response.data && response.data.thumbnail
              ? response.data.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :fromUrl="true"
          width="782"
          height="435"
        />
      </div>
      <div class="card-detail">
        <div class="primary">
          <div class="categories">
            <div class="category">
              {{
                response.data && response.data.category
                  ? response.data.category.title
                  : "Uncategorized"
              }}
            </div>
          </div>
          <h1 class="title">{{ response.data.title }}</h1>
          <div class="desc-wrapper">
            <p
              class="desc"
              v-html="
                response.data.description
                  .replace(/(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/gim, ' ')
                  .substr(0, limit)
              "
            ></p>
            <span
              v-if="
                response.data &&
                response.data.description.replace(
                  /(<p[^>]+?>|<p>|<\/p>|<br>|<\/br>)/gim,
                  ' '
                ).length > 300
              "
              @click="limit == 300 ? (limit = 500) : (limit = 300)"
              v-html="limit == 300 ? '<span>...</span> read more' : 'read less'"
            ></span>
          </div>
          <div class="price">
            <p
              class="discount"
              v-if="response.data && response.data.discount !== 0"
            >
              {{ changeToRupiah(response.data.price) }}
            </p>
            <p
              class="real-price"
              v-if="response.data && response.data.discountPrice"
            >
              {{ changeToRupiah(response.data.discountPrice) }}
            </p>
          </div>
        </div>
        <div class="secondary">
          <div class="benefit">
            <div class="box level">
              <p class="text">TINGKATAN</p>
              <div class="icon-wrapper">
                <Icons :name="checkClassLevel" />
                <p class="text">{{ response.data.classLevel }}</p>
              </div>
            </div>
            <div class="box record">
              <p class="text">REKAMAN</p>
              <Icons
                :name="response.data.hasRecording ? 'checked' : 'cancel'"
              />
            </div>
            <div class="box certificate">
              <p class="text">SERTIFIKAT</p>
              <Icons
                :name="response.data.hasCertificate ? 'checked' : 'cancel'"
              />
            </div>
            <div class="box location">
              <p class="text">LOKASI</p>
              <p class="class-location">
                {{
                  response.data.location.length > 35
                    ? response.data.location.substring(0, 35) + "..."
                    : response.data.location
                }}
              </p>
            </div>
          </div>
          <div class="btn-wrapper">
            <span
              v-if="response.data"
              class="btn-ikuti-kelas"
              :class="
                response.data.paymentStatus == 'PENDING'
                  ? 'btn-tag-bayar-sekarang'
                  : response.data.paymentStatus == 'SUCCESS'
                  ? 'btn-tag-masuk-kelas'
                  : 'btn-tag-daftar-kelas'
              "
              @click="beliKelas()"
              >{{
                response.data.paymentStatus == "PENDING"
                  ? "BAYAR SEKARANG"
                  : response.data.paymentStatus == "SUCCESS"
                  ? "MASUK KELAS"
                  : "DAFTAR KELAS"
              }}</span
            >
          </div>
        </div>
      </div>
    </section>
    <section class="speaker" v-show="response.data.speakers">
      <div class="container">
        <TitleSection title="BELAJAR DENGAN AHLINYA" :border="false" />

        <div
          class="speaker-detail"
          :class="{
            mb: response.data.speakers && response.data.speakers.length > 1,
          }"
          v-for="(speaker, index) in response.data.speakers"
          :key="'speaker-' + index"
        >
          <div class="thumbnail">
            <ImageResponsive
              width="400"
              height="400"
              widthMobile="300"
              heightMobile="300"
              :imageUrl="
                speaker.image
                  ? speaker.image
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :fromUrl="true"
            />
            <div class="name-title-mobile">
              <p class="speaker-name">
                {{ speaker.name ? speaker.name : "" }}
              </p>
              <p class="speaker-title">
                {{ speaker.title ? speaker.title : "" }}
              </p>
            </div>
          </div>
          <div class="copy-wrapper">
            <p class="speaker-name">
              {{ speaker.name ? speaker.name : "" }}
            </p>
            <p class="speaker-title">
              {{ speaker.title ? speaker.title : "" }}
            </p>
            <p
              class="speaker-desc"
              v-html="speaker.description ? speaker.description : ''"
            ></p>
          </div>
        </div>
      </div>
    </section>
    <section class="about-class" v-show="response.data.learn.length > 0">
      <div class="container">
        <TitleSection
          title="APA SAJA YANG AKAN KAMU DAPATKAN?"
          :border="false"
        />
        <ul>
          <li v-for="learn in response.data.learn" :key="learn.id">
            {{ learn }}
          </li>
        </ul>
      </div>
    </section>
    <section class="what-you-learn" v-show="response.data.lessons.length > 0">
      <div class="container">
        <TitleSection
          title="HAL APA SAJA YANG AKAN KAMU PELAJARI"
          :border="false"
        />
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div
            class="accordion-item"
            v-for="(lesson, index) in response.data.lessons"
            :key="'lessons-' + index"
          >
            <h2
              class="accordion-header"
              :id="'panelsStayOpen-headingOne' + index"
            >
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#panelsStayOpen-collapseOne' + index"
                aria-expanded="true"
                :aria-controls="'panelsStayOpen-collapseOne' + index"
              >
                <h2 class="number">0{{ index + 1 }}</h2>
                <h2 class="title">{{ lesson.title }}</h2>
              </button>
            </h2>
            <div
              :id="'panelsStayOpen-collapseOne' + index"
              class="accordion-collapse collapse show"
              :aria-labelledby="'panelsStayOpen-headingOne' + index"
            >
              <div class="accordion-body">
                <div class="desc-wrapper">
                  <p class="desc" v-html="lesson.description"></p>
                  <ul>
                    <li
                      v-for="(item, index) in lesson.material"
                      :key="'material-' + index"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="share container"
      :class="{ 'pt-5': response.data.lessons.length == 0 }"
    >
      <h2 class="title">Bagikan:</h2>
      <div class="share-wrapper">
        <a
          :href="setLink('whatsapp')"
          target="_blank"
          rel="noopener"
          class="sosmed-wrapper share-academy"
        >
          <Icons name="whatsapp" />
        </a>
        <a
          :href="setLink('twitter')"
          target="_blank"
          rel="noopener"
          class="sosmed-wrapper share-academy"
        >
          <Icons name="logo-x-blue" />
        </a>
        <a
          :href="setLink('facebook')"
          target="_blank"
          rel="noopener"
          class="sosmed-wrapper share-academy"
        >
          <Icons name="facebook" />
        </a>
        <div class="sosmed-wrapper share-academy" @click="copyLink()">
          <Icons name="copy-link" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ModalComp from "../../../components/modal/ModalComp.vue";
import config from "../../../config";
import CardEleven from "/components/cards/CardEleven";
import ModalLogin from "/components/modal/Login.vue";
import ModalShare from "/components/modal/Share";
import ModalVoucher from "/components/modal/Voucher";
import ImageResponsive from "/components/ImageResponsive";
import Icons from "/components/Icons";
import TitleSection from "/components/TitleSection";

export default {
  async asyncData({ app, params, store, route, error }) {
    try {
      store.commit("config/setLoading", true);
      const response = await app.$axios
        .$get(`${config.ACADEMY_SERVICE_API}class/${params.slug}`)
        .catch((e) => {
          error({ statusCode: 404, message: "Page not found" });
        });
      store.commit("seo/SET_SEO", {
        title: response.data.title,
        desc: response.data.description,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      store.commit("config/setLoading", false);
      return { response };
    } catch (err) {
      console.log(err);
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    TitleSection,
    Icons,
    ImageResponsive,
    CardEleven,
    ModalLogin,
    ModalShare,
    ModalVoucher,
    ModalComp,
  },
  data() {
    return {
      url: "",
      modalInfo1: false,
      modalInfo2: false,
      modalLogin: false,
      modalShare: false,
      modalVoucher: true,
      limit: 300,
      // priceAfterDiscount: 0,
      voucherCode: "",
    };
  },
  beforeRouteLeave(to, from, next) {
    this.modalLogin.hide();
    this.modalInfo1.hide();
    this.modalInfo2.hide();
    next();
  },
  // watch: {
  //   "$route.query.materi": {
  //     handler: function (value) {
  //       if (value === "sertifikat" && !this.$store.state.auth.loggedIn) {
  //         return this.$nuxt.error({ statusCode: 404, message: "Error" });
  //       }
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    // ...mapState({
    //   academy: (state) => {
    //     return state.academies.academyDetailClass;
    //   },
    // }),
    academy() {
      return this.response.data;
    },
    checkClassLevel() {
      if (this.response.data.classLevel === "BEGINNER") {
        return "beginner";
      } else {
        return "professional";
      }
    },
  },
  jsonld() {
    if (!this.response.data) {
      return null;
    }

    return [
      {
        "@context": "https://schema.org",
        "@type": "Course",
        name: this.response.data.title,
        description: `${this.response.data.description
          .replace(/(<([^>]+)>)/gi, "")
          .substring(0, 306)}...`,
        provider: {
          "@type": "Organization",
          name: "Narasi Academy",
          sameAs: "https://narasi.tv",
        },
      },
    ];
  },
  methods: {
    // getPrice(isNumber = false) {
    //   let price = this.response.data.price;
    //   let discountInPercent = this.response.data.discount;
    //   let realPrice = price;
    //   if (discountInPercent !== 0) {
    //     let priceDiscount = (price / 100) * discountInPercent;
    //     realPrice = price - priceDiscount;
    //   }
    //   console.log(realPrice, price);
    //   if (realPrice) {
    //     if (realPrice === 0) {
    //       return "Gratis";
    //     } else {
    //       if (isNumber) {
    //         return realPrice;
    //       }
    //       return this.changeToRupiah(realPrice);
    //     }
    //   }
    // },
    // async initData() {
    // await this.$store.dispatch("academies/getDetailClass", {
    //   slug: this.$route.params.slug,
    // });
    // },
    checkoutClass() {
      // if (this.response.data.discount !== 0) {
      //   let priceDiscount = (this.response.data.price / 100) * this.response.data.discount;
      //   this.priceAfterDiscount = this.response.data.price - priceDiscount;
      // } else {
      //   this.priceAfterDiscount = this.response.data.price;
      // }

      const discountVoucher = this.$store.state.voucher.data.detail
        ? this.$store.state.voucher.data.detail.amountDiscount
        : 0;

      const total = this.response.data.discountPrice - discountVoucher;
      let items = [
        {
          referenceId: this.response.data.id,
          name: this.response.data.title.slice(0, 50),
          qty: 1,
          price: this.response.data.discountPrice,
          discount: discountVoucher,
        },
      ];

      if (total <= 0 || total >= 10000) {
        this.$axios
          .$post(`${config.PAYMENT_SERVICE_API}api/payments/snap`, {
            type: "academy",
            userId: this.$store.state.auth.user.id,
            fullName: this.$store.state.auth.user.fullname,
            email: this.$store.state.auth.user.email,
            phone: this.$store.state.auth.user.phoneNumber,
            gender: this.$store.state.auth.user.gender,
            birthdate: this.$store.state.auth.user.birthdate,
            city: this.$store.state.auth.user.city,
            interest: this.$store.state.auth.user.interests,
            occupation: this.$store.state.auth.user.occupation,
            level: this.response.data.classLevel,
            items: items,
            voucherCode: this.$store.state.voucher.data.isValid
              ? this.voucherCode
              : "",
            callbacks: `${config.BASE_URL}/academy/complete/${this.response.data.slug}`,
            classUrl: this.response.data.classUrl,
          })
          .then((response) => {
            window.location = response.data.redirectUrl;
          })
          .catch((e) => {
            this.$toast.error(
              "Maaf, sistem sedang sibuk. Silakan coba beberapa saat lagi."
            );
            console.log(e.response);
          });
      } else {
        this.$toast.error(
          "Oops, tidak dapat melanjutkan pesanan karna total pesanan anda kurang dari Rp. 10.000"
        );
      }
    },
    beliKelas() {
      if (!this.$store.state.auth.loggedIn) {
        return this.modalLogin.show();
      } else if (this.response.data.paymentStatus === "PENDING") {
        window.open(this.response.data.classUrl);
      } else if (this.response.data.paymentStatus === "SUCCESS") {
        setTimeout(() => {
          window.open(
            `https://course.narasi.tv/sign/${
              this.$route.params.slug
            }?token=${this.$axios.defaults.headers.common[
              "Authorization"
            ].replace("Bearer ", "")}`
          );
        });
      } else {
        this.modalVoucher.show();
      }
    },
    setLink(param) {
      switch (param) {
        case "whatsapp":
          return (
            "https://wa.me/?text=" +
            this.url +
            "%3Futm_source=whatsapp%26utm_medium=share"
          );
        case "facebook":
          return (
            "https://www.facebook.com/sharer.php?u=" +
            this.url +
            "?utm_source=facebook&utm_medium=share"
          );
        case "twitter":
          return (
            "https://twitter.com/intent/tweet?url=" +
            this.url +
            "?utm_source=twitter&utm_medium=share"
          );
      }
    },
    copyLink() {
      navigator.clipboard.writeText(
        this.url + "?utm_source=copy_link&utm_medium=share"
      );
      this.$toast.success("Copy Link Success");
    },
    changeToRupiah(data) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      })
        .format(data)
        .split(",")[0]
        .replace(/\s/g, "");
      // let bilangan = data;
      // let reverse = bilangan.toString().split("").reverse().join(""),
      //   ribuan = reverse.match(/\d{1,3}/g);
      // ribuan = ribuan.join(".").split("").reverse().join("");
      // return "Rp. " + ribuan;
    },
  },
  mounted() {
    // this.initData();
    this.url = window.location.href;
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  @media only screen and (max-width: 1024px) {
    padding-bottom: 0;
  }

  .price-modal {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    line-height: 40px;
    .price {
      font-size: 16px;
      color: #757575;
    }
  }

  .price-modal-total {
    margin-bottom: 5px;
    border-top: 1px solid #e0e0e0;
    line-height: 40px;
    .price-total {
      font-weight: 500;
      font-size: 14px;
      color: #616161;
    }
    .price-total-format {
      font-size: 20px;
      color: #4a25aa;
      font-weight: 500;
    }
  }

  #modal-share {
    .modal-header {
      padding: 30px 30px 20px;
      border: none;

      .modal-title {
        font-size: 24px;
        font-weight: 500;
        color: #4a25aa;
      }

      .close {
        cursor: pointer;
        opacity: 1;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 30px;

      .sosmed-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        svg {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }

        .sosmed {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
  }

  .academy-card {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
    .card-thumbnail {
      width: 55%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .card-detail {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      .primary {
        padding: 20px 250px 20px 40px;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (max-width: 1500px) {
          padding: 0 180px 0 40px;
        }
        @media only screen and (max-width: 1024px) {
          padding: 30px 20px;
          height: fit-content;
        }
        .categories {
          display: flex;
          flex-direction: row;
          margin-bottom: 16px;
          @media only screen and (max-width: 1500px) {
            margin-bottom: 12px;
          }
          @media only screen and (max-width: 1024px) {
            margin-bottom: 10px;
          }
          .category {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #616161;
            padding: 6px 20px;
            margin-right: 8px;
            border: 1px solid #616161;
            border-radius: 50px;
            @media only screen and (max-width: 1500px) {
              font-size: 12px;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 10px;
              padding: 4px 15px;
            }
          }
        }
        .title {
          font-size: 32px;
          line-height: 48px;
          font-weight: 500;
          color: #4a25aa;
          margin-bottom: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 5px;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 12px;
          }
        }
        .desc-wrapper {
          margin-bottom: 16px;
          @media only screen and (max-width: 1500px) {
            margin-bottom: 10px;
          }
          .desc {
            font-size: 14px;
            color: #757575;
            display: inline;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // -webkit-line-clamp: 5;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            @media only screen and (max-width: 1500px) {
              font-size: 10px;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
          span {
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            @media only screen and (max-width: 1500px) {
              font-size: 10px;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
            span {
              font-weight: 400;
            }
          }
        }
        .price {
          display: flex;
          align-items: center;
          .discount {
            font-size: 14px;
            font-weight: 500;
            color: #9e9e9e;
            margin: 0;
            margin-right: 20px;
            text-decoration: line-through;
            @media only screen and (max-width: 1500px) {
              font-size: 12px;
              margin-bottom: 10px;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 10px;
              margin-right: 16px;
            }
          }
          .real-price {
            font-size: 24px;
            font-weight: 500;
            color: #4a25aa;
            margin: 0;
            @media only screen and (max-width: 1500px) {
              font-size: 18px;
            }
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
            }
          }
        }
      }
      .secondary {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 40px;
        background: #fafafa;
        height: 20%;
        @media only screen and (max-width: 1024px) {
          padding: 25px 20px;
          flex-direction: column;
          height: fit-content;
        }
        .benefit {
          display: flex;
          flex-direction: row;
          max-width: 70%;
          @media only screen and (max-width: 1024px) {
            max-width: unset;
          }
          .box {
            padding: 0 24px;
            text-align: center;
            border-right: 1px solid #e0e0e0;
            @media only screen and (max-width: 1500px) {
              padding: 0 10px;
            }
            @media only screen and (max-width: 1024px) {
              padding: 0 15px;
            }
            .text {
              @media only screen and (max-width: 1500px) {
                font-size: 10px;
                margin-bottom: 4px;
              }
              @media only screen and (max-width: 1024px) {
                font-size: 10px;
                margin-bottom: 8px;
              }
            }
            svg {
              @media only screen and (max-width: 1500px) {
                width: 15px;
                height: 15px;
              }
            }
            &:first-child {
              padding: 0;
              padding-right: 24px;
              @media only screen and (max-width: 1024px) {
                padding-right: 20px;
              }
            }
            &:last-child {
              border: none;
            }
            &.level {
              text-align: left;
              .icon-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                svg {
                  margin-right: 12px;
                  @media only screen and (max-width: 1500px) {
                    width: 15px;
                    height: auto;
                  }
                }
                .text {
                  font-size: 14px;
                  font-weight: 600;
                  color: #051c2c;
                  margin: 0;
                  @media only screen and (max-width: 1500px) {
                    font-size: 12px;
                  }
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }
              }
            }
            &.location {
              .class-location {
                font-size: 14px;
                font-weight: 600;
                color: #051c2c;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1500px) {
                  font-size: 12px;
                }
                @media only screen and (max-width: 1024px) {
                  font-size: 10px;
                  -webkit-line-clamp: 1;
                }
              }
            }
          }
        }
        .btn-wrapper {
          margin-left: 30px;
          @media only screen and (max-width: 1024px) {
            margin: 0;
            margin-top: 30px;
            width: 100%;
          }
          .btn-ikuti-kelas {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            white-space: nowrap;
            font-weight: 700;
            color: #fafafa;
            background: #4a25aa;
            padding: 14px 20px;
            border-radius: 4px;
            @media only screen and (max-width: 1500px) {
              font-size: 12px;
            }
            &:hover {
              color: #fafafa !important;
            }
          }
        }
      }
    }
  }

  .speaker {
    padding: 48px 0;
    border-bottom: 2px solid #e0e0e0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
      .title {
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }
      }
      .speaker-detail {
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
        }
        &.mb {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
        .thumbnail {
          width: 12%;
          height: fit-content;
          border-radius: 6px;
          overflow: hidden;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
            width: 100%;
            display: flex;
            flex-direction: row;
            border-radius: 0;
          }
          .image {
            @media only screen and (max-width: 1024px) {
              width: 20%;
              border-radius: 3px;
            }
          }
          .name-title-mobile {
            display: none;
            @media only screen and (max-width: 1024px) {
              display: flex;
              flex-direction: column;
              padding-left: 20px;
            }
            .speaker-name {
              @media only screen and (max-width: 1024px) {
                font-size: 16px;
                font-weight: 500;
                color: #0c0b0d;
                margin: 0;
              }
            }
            .speaker-title {
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
                color: #0c0b0d;
                margin: 0;
              }
            }
          }
        }
        .copy-wrapper {
          width: 88%;
          padding-left: 30px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0;
          }
          .speaker-name {
            font-size: 20px;
            font-weight: 500;
            color: #0c0b0d;
            margin-bottom: 8px;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }
          .speaker-title {
            font-size: 16px;
            color: #0c0b0d;
            margin-bottom: 16px;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }
          .speaker-desc {
            font-size: 14px;
            line-height: 28px;
            color: #0c0b0d;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              line-height: 150%;
            }
            ::v-deep p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .about-class {
    padding: 48px 0;
    border-bottom: 2px solid #e0e0e0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
      .title {
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        @media only screen and (max-width: 1024px) {
          margin: 0;
        }
        li {
          list-style-type: none;
          font-size: 16px;
          font-weight: 500;
          color: #0c0b0d;
          background: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12.8828' r='12' fill='%23EBE4FC'/%3E%3Cpath d='M10.3147 14.7952L8.21472 12.6952C7.98072 12.4612 7.60872 12.4612 7.37472 12.6952C7.14072 12.9292 7.14072 13.3012 7.37472 13.5352L9.88872 16.0492C10.1227 16.2832 10.5007 16.2832 10.7347 16.0492L17.0947 9.69519C17.3287 9.46119 17.3287 9.08919 17.0947 8.85519C16.8607 8.62119 16.4887 8.62119 16.2547 8.85519L10.3147 14.7952Z' fill='%2334128C'/%3E%3C/svg%3E%0A")
            no-repeat left;
          padding-left: 35px;
          margin-bottom: 8px;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .what-you-learn {
    padding: 48px 0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
      .title {
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }
      }
      .accordion {
        .accordion-item {
          margin-bottom: 32px;
          border-radius: 6px;
          overflow: hidden;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 12px;
          }
          &:last-child {
            margin: 0;
          }
          .accordion-header {
            .accordion-button {
              background: #ffffff;
              box-shadow: none;
              display: flex;
              flex-direction: row;
              &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L10 10.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289Z' fill='%23424242'/%3E%3C/svg%3E%0A");
                background-size: 25px;
                width: 25px;
                height: 25px;
                @media only screen and (max-width: 1024px) {
                  background-size: 20px;
                  width: 20px;
                  height: 20px;
                }
              }
              .number {
                width: 5%;
                font-size: 24px;
                font-weight: 600;
                color: #ffe900;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  width: 15%;
                  font-size: 16px;
                }
              }
              .title {
                width: 95%;
                font-size: 20px;
                font-weight: 600;
                color: #616161;
                margin: 0;
                @media only screen and (max-width: 1024px) {
                  width: 85%;
                  font-size: 16px;
                }
              }
            }
          }
          .accordion-collapse {
            .accordion-body {
              display: flex;
              justify-content: flex-end;
              @media only screen and (max-width: 1024px) {
                padding-top: 0;
              }
              .desc-wrapper {
                width: 95%;
                @media only screen and (max-width: 1024px) {
                  width: 85%;
                }
                .desc {
                  font-size: 18px;
                  color: #616161;
                  @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 24px;
                  }
                }
                ul {
                  list-style: none;
                  padding-left: 0;
                  li {
                    list-style-type: none;
                    font-size: 16px;
                    font-weight: 500;
                    color: #616161;
                    background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_300_8640' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23616161'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_300_8640)'%3E%3Cpath d='M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H10L12 6H20C20.55 6 21.021 6.196 21.413 6.588C21.8043 6.97933 22 7.45 22 8H4V18L6.4 10H23.5L20.925 18.575C20.7917 19.0083 20.546 19.3543 20.188 19.613C19.8293 19.871 19.4333 20 19 20H4Z' fill='%23616161'/%3E%3C/g%3E%3C/svg%3E")
                      no-repeat left;
                    padding-left: 35px;
                    margin-bottom: 8px;
                    @media only screen and (max-width: 1024px) {
                      font-size: 12px;
                      padding-left: 25px;
                      background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_300_8640' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23616161'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_300_8640)'%3E%3Cpath d='M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H10L12 6H20C20.55 6 21.021 6.196 21.413 6.588C21.8043 6.97933 22 7.45 22 8H4V18L6.4 10H23.5L20.925 18.575C20.7917 19.0083 20.546 19.3543 20.188 19.613C19.8293 19.871 19.4333 20 19 20H4Z' fill='%23616161'/%3E%3C/g%3E%3C/svg%3E")
                        no-repeat left top;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
    .title {
      font-size: 14px;
      margin-bottom: 16px;
    }
    .share-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      .sosmed-wrapper {
        svg {
          width: 36px;
          height: 36px;
          margin-left: 12px;
          @media only screen and (max-width: 1024px) {
            width: 24px;
            height: 24px;
            margin-right: 10px;
            margin-left: unset;
          }
        }
      }
    }
  }
}
</style>
