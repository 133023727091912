<template>
  <div class="content">
    <section class="class-category">
      <div class="container">
        <div
          class="py-2"
          v-swiper:mySwiperClassCategory="swiperOptionsClassCategory"
        >
          <div class="swiper-wrapper">
            <ShimmerCategory v-if="kelas.loading" />
            <Link
              class="swiper-slide category"
              v-for="item in kelas.items"
              :key="item.id"
              :to="{
                name: 'academy-kelas',
                query: {
                  type: item.slug,
                },
              }"
              >{{ item.name }}
            </Link>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
    <section class="list-card-class-category">
      <div class="container">
        <ShimmerCardSeeAll v-if="academies.loading" />
        <CardFourteen
          v-for="card in academies.items"
          :key="card.id"
          :image="card.image"
          :ticket="card.discount !== 100 ? 'Berbayar' : 'Gratis'"
          :category="card.category"
          :title="card.title"
          :slug="card.slug"
          :description="card.description"
          :datetime="card.date"
          :price="card.price"
          :discount="card.discount"
          :to="{
            name: 'academy-detail-slug',
            params: {
              slug: card.slug,
            },
            query: {
              'tipe-kelas': card.classType.slug,
            },
          }"
          :classType="card.classType"
          class="card-class-category"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import CardFourteen from "/components/cards/CardFourteen";
import Link from "/components/Link";
import ShimmerCategory from "/components/shimmer/Category";
import ShimmerCardSeeAll from "/components/shimmer/CardSeeAll";

export default {
  async asyncData({ app, params, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Kelas Academy",
        desc: "Narasi Academy",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    ShimmerCardSeeAll,
    ShimmerCategory,
    Link,
    CardFourteen,
  },
  data() {
    return {
      swiperOptionsClassCategory: {
        // freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        mousewheel: {
          releaseOnEdges: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: "auto",
            spaceBetween: 10,
            enabled: true,
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      kelas: (state) => {
        return state.academies.academyClass;
      },
      academies: (state) => {
        return state.academies.academyByCategory;
      },
    }),
  },
  watch: {
    "$route.query.type": {
      handler: function (search) {
        this.initData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async initData() {
      await this.$store.dispatch("academies/getClass");
      await this.$store.dispatch("academies/getAcademyByCategory", {
        slug: this.$route.query.type,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 32px 0;
  @media only screen and (max-width: 1024px) {
    padding: 30px 0;
  }

  .class-category {
    margin-bottom: 32px;
    @media only screen and (max-width: 1024px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .swiper-container {
      @media only screen and (max-width: 1024px) {
        padding: 20px;
        margin: -20px;
      }

      .swiper-wrapper {
        height: fit-content !important;
        @media only screen and (max-width: 1024px) {
          flex-wrap: nowrap;
          // flex-wrap: wrap;
        }

        .swiper-slide {
          &.category {
            color: #616161;
            background: #fafafa;
            width: fit-content !important;
            border-radius: 50px;
            white-space: nowrap;
            font-size: 16px;
            padding: 10px 30px;
            border: 1px solid #e0e0e0;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              padding: 5px 20px;
              margin: 0 5px 5px 0;
            }

            &:hover {
              @media only screen and (min-width: 1024px) {
                background: #8166c8;
                color: #fafafa !important;
              }
            }

            &:active {
              @media only screen and (min-width: 1024px) {
                background: #6242b4;
                color: #e0e0e0 !important;
              }
            }

            &.Link-exact-active {
              background: #4a25aa;
              color: #ffe900 !important;
            }
          }
        }
      }
    }
  }

  .list-card-class-category {
    .card-class-category {
      margin-bottom: 16px;
    }
  }
}
</style>
