<template>
  <div class="content">
    <section id="academy-banner">
      <div class="container">
        <ShimmerTopCarousel v-if="banner.loading" />
        <div v-if="banner.items.length > 0">
          <Banner :data="banner.items" width="1440" height="350" />
        </div>
      </div>
    </section>
    <div id="academy-content">
      <section id="about-academy">
        <div class="container">
          <div class="about-academy-content">
            <div class="about-academy-detail">
              <h2 class="about-academy-title">
                Kembangkan Potensi Diri bersama Narasi Academy!
              </h2>
              <p class="about-academy-desc">
                Dari kelas jurnalistik, pembuatan konten, mengasah kreativitas,
                desain visual hingga perkembangan industri digital ada di Narasi
                Academy. Semua topik menghadirkan mentor profesional di
                bidangnya, yang dikemas informatif dan aktual. Pilih kelasmu,
                dan jadilah kolaborator di Narasi.
              </p>
            </div>
            <div class="about-academy-thumbnail">
              <ImageResponsive
                imageUrl="https://storage.googleapis.com/narasi-production.appspot.com/production/academy/logo/30%20Mei%202022_Narasi%20Academy%20Picture%20Description.png"
                :fromUrl="true"
                width="500"
                height="376"
                alt="thumbnail-about-academy"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- <section id="lanjutkan-kelas" v-if="$store.state.auth.loggedIn">
        <div class="container">
          <TitleSection title="KELAS SAYA" class="lanjutkan-kelas-title" />
          <div class="lanjutkan-kelas-list">
            <div v-swiper:mySwiperLanjutkanKelas="swiperOptions.lanjutkanKelas">
              <div class="swiper-wrapper">
                <ShimmerLanjutkanKelas v-if="kelasSaya.loading" />
                <div
                  class="lanjutkan-kelas-card swiper-slide"
                  v-for="item in kelasSaya.data.data"
                  :key="item.id"
                >
                  <ImageResponsive
                    imageUrl="https://narasi.tv/storage/images/dummy-square.png"
                    :fromUrl="true"
                    class="lanjutkan-kelas-thumbnail"
                    alt="thumbnail-lanjutkan-kelas"
                    width="100"
                    height="100"
                    widthMobile="100"
                    heightMobile="100"
                  />
                  <div class="lanjutkan-kelas-card-content">
                    <div class="copy-wrapper">
                      <div class="top">
                        <p
                          class="lanjutkan-kelas-card-kategori"
                          v-for="category in item.course.categories"
                          :key="category.id"
                        >
                          {{ category.title }}
                        </p>
                        <Link
                          :to="{
                            name: 'academy-slug',
                            params: {
                              slug: item.course.slug,
                            },
                          }"
                          class="lanjutkan-kelas-card-title"
                        >
                          {{ item.course.title }}
                        </Link>
                      </div>
                      <div class="bottom">
                        <p class="lanjutkan-kelas-card-total-materi">
                          3/5 Materi Video
                        </p>
                      </div>
                    </div>
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="`width: ${item.progressPercentage}%`"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="shadow-arrow shadow-left prev-lanjutkan-kelas">
              <Icons name="arrow-left" class="arrow prev" />
            </div>
            <div class="shadow-arrow shadow-right next-lanjutkan-kelas">
              <Icons name="arrow-right" class="arrow next" />
            </div>
          </div>
        </div>
      </section> -->
      <section
        id="ikuti_kelas"
        :class="
          !ikutiKelas.loading && ikutiKelas.items.length <= 0 ? 'mt-0' : ''
        "
      >
        <div
          class="container"
          v-if="!ikutiKelas.loading && ikutiKelas.items.length > 0"
        >
          <div class="title-content">
            <TitleSection title="IKUTI KELAS" class="kenali-mentor-title" />
            <!-- <Link
              v-if="ikutiKelas.items && ikutiKelas.items.length > 4"
              :to="{
                name: 'event-sedang-berjalan',
              }"
              class="see-all"
              >Lihat Semua
            </Link> -->
          </div>
        </div>
        <div class="container overflow-hidden">
          <div class="position-relative">
            <div v-swiper:mySwiperIkutiKelas="swiperOptions.ikuti_kelas">
              <div class="swiper-wrapper">
                <ShimmerListCard v-if="ikutiKelas.loading" />
                <CardTwentyThree
                  class="swiper-slide"
                  v-for="(card, index) in ikutiKelas.items"
                  v-show="ikutiKelas.items.length > 0"
                  :key="'card-ikuti-kelas' + index"
                  :image="
                    card.thumbnail && card.thumbnail.large
                      ? card.thumbnail.large
                      : 'https://narasi.tv/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :categories="card.category"
                  :classType="card.classType"
                  :title="card.title"
                  :slug="card.slug"
                  :description="card.description"
                  :datetime="card.startDate"
                  :isSoon="card.isSoon"
                  :price="card.price"
                  :discountPrice="card.discountPrice"
                  :discount="card.discount"
                  :to="{
                    name: 'academy-detail-slug',
                    params: {
                      slug: card.slug,
                    },
                  }"
                  :startEvent="card.startDate"
                  :endEvent="card.endDate"
                  :startRegis="card.startRegistrationDate"
                  :endRegis="card.endRegistrationDate"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-ikuti_kelas"
              v-show="ikutiKelas.items.length > 3"
            >
              <Icons
                name="arrow-left"
                class="arrow prev"
                v-show="ikutiKelas.items.length > 3"
              />
            </div>
            <div
              class="shadow-arrow shadow-right next-ikuti_kelas"
              v-show="ikutiKelas.items.length > 3"
            >
              <Icons
                name="arrow-right"
                class="arrow next"
                v-show="ikutiKelas.items.length > 3"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- <section id="masuk-kelas">
        <div class="container">
          <TitleSection title="MASUK KELAS" class="masuk-kelas-title" />
          <div class="masuk-kelas-list">
            <div
              v-swiper:mySwiperMasukKelas="swiperOptions.masukKelas"

            >
              <div class="swiper-wrapper">
                <ShimmerMasukKelas v-if="kelas.loading" />
                <Link
                  v-for="item in kelas.items"
                  :key="item.id"
                  class="masuk-kelas-card swiper-slide"
                  :to="{
                    name: 'academy-kelas',
                    query: {
                      type: item.slug,
                    },
                  }"
                >
                  <ImageResponsive
                    v-if="item.slug"
                    :imageUrl="'academy/kelas/' + item.slug + '.png'"
                    class="masuk-kelas-thumbnail"
                    alt="thumbnail-kategori-kelas"
                  />
                  <p class="masuk-kelas-card-title">
                    {{ item.name }}
                  </p>
                </Link>
              </div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-masuk-kelas"
            >
            <Icons
              name="arrow-left"
              class="arrow prev"
            /></div>
            <div
              class="shadow-arrow shadow-right next-masuk-kelas"
            >
            <Icons
              name="arrow-right"
              class="arrow next"
            /></div>
          </div>
        </div>
      </section> -->
      <!-- <section id="kelas-terdaftar">
        <div class="container">
          <TitleSection
            title="KELAS YANG KAMU DAFTARKAN"
            class="kelas-terdaftar-title"
          />
          <div class="kelas-terdaftar-list">
            <div
              v-swiper:mySwiperKelasTerdaftar="swiperOptions.kelasTerdaftar"

            >
              <div class="swiper-wrapper">
                <ShimmerKelasTerdaftar v-if="allData.loading" />
                <div
                  class="kelas-terdaftar-card swiper-slide"
                  v-for="item in allData.items"
                  :key="item.id"
                >
                  <div class="kelas-terdaftar-card-header">
                    <div class="kelas-terdaftar-card-header-content">
                      <p class="kelas-terdaftar-card-kategori">
                        {{ item.category.name }}
                      </p>
                      <p class="kelas-terdaftar-card-title">
                        {{ item.title }}
                      </p>
                    </div>
                    <div class="kelas-terdaftar-card-btn-wrapper">
                      <Link
                        :to="{
                          name: 'academy-detail-slug',
                          params: {
                            slug: item.slug,
                          },
                          query: {
                            'tipe-kelas': item.classType.slug,
                          },
                        }"
                        class="kelas-terdaftar-card-btn-daftar"
                        >DAFTAR KELAS
                      </Link>
                    </div>
                  </div>
                  <div class="kelas-terdaftar-card-body">
                    <ImageResponsive
                      imageUrl="academy/kelas-yang-kamu-daftarkan/kelas-terdaftar.png"
                      class="kelas-terdaftar-card-thumbnail"
                      alt="thumbnail-kelas-yang-kamu-daftarkan"
                    />
                    <div class="kelas-terdaftar-card-body-content">
                      <div class="box kelas-terdaftar-card-paid-status">
                        <Icons name="paid-status" />
                        <p class="text">Canceled</p>
                      </div>
                      <div class="box kelas-terdaftar-card-tipe-kelas">
                        <Icons name="tipe-kelas" />
                        <p class="text">{{ item.classType.name }}</p>
                      </div>
                      <div class="box kelas-terdaftar-card-date">
                        <Icons name="calendar" />
                        <p class="text">{{ item.date }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-kelas-terdaftar"
            >
            <Icons
              name="arrow-left"
              class="arrow prev"
            /></div>
            <div
              class="shadow-arrow shadow-right next-kelas-terdaftar"
            >
            <Icons
              name="arrow-right"
              class="arrow next"
            /></div>
          </div>
        </div>
      </section> -->
      <section id="kenali-mentor">
        <div class="container">
          <TitleSection
            title="KENALI MENTOR NARASI ACADEMY"
            class="kenali-mentor-title"
          />
          <div class="kenali-mentor-list">
            <div v-swiper:mySwiperKenaliMentor="swiperOptions.kenaliMentor">
              <div class="swiper-wrapper">
                <ShimmerKenaliMentor v-if="mentor.loading" />
                <div
                  class="kenali-mentor-card swiper-slide"
                  v-for="item in mentor.items"
                  :key="item.id"
                >
                  <ImageResponsive
                    v-if="item.image"
                    :imageUrl="item.image"
                    :fromUrl="true"
                    width="200"
                    height="200"
                    class="kenali-mentor-card-thumbnail"
                    alt="thumbnail-mentor"
                  />
                  <div class="kenali-mentor-card-content">
                    <Link
                      :to="{
                        name: 'academy-mentor-slug',
                        params: {
                          slug: item.slug,
                        },
                      }"
                      class="kenali-mentor-card-name"
                      >{{ item.name }}
                    </Link>
                    <p class="kenali-mentor-card-profession">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              v-show="mentor.items.length > 5"
              class="shadow-arrow shadow-left prev-kenali-mentor"
            >
              <Icons
                v-show="mentor.items.length > 5"
                name="arrow-left"
                class="arrow prev"
              />
            </div>
            <div
              v-show="mentor.items.length > 5"
              class="shadow-arrow shadow-right next-kenali-mentor"
            >
              <Icons
                v-show="mentor.items.length > 5"
                name="arrow-right"
                class="arrow next"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="kata-mereka">
        <div class="container">
          <TitleSection
            title="KATA MEREKA TENTANG NARASI ACADEMY"
            class="kata-mereka-title"
          />
          <div class="kata-mereka-list">
            <div v-swiper:mySwiperKataMereka="swiperOptions.kataMereka">
              <div class="swiper-wrapper">
                <ShimmerKenaliMentor v-if="testimoni.loading" />
                <div
                  v-show="!testimoni.loading"
                  v-for="(item, index) in testimoni.items"
                  class="kata-mereka-card swiper-slide"
                  :key="'testimoni-' + index"
                >
                  <div class="copy">
                    <p class="copy-title">
                      {{ item.descriptionTitle }}
                    </p>
                    <p class="copy-desc">
                      {{ item.description }}
                    </p>
                  </div>
                  <div class="profile">
                    <div class="profile-image">
                      <ImageResponsive
                        :imageUrl="item.image"
                        :fromUrl="true"
                        width="50"
                        height="50"
                        widthMobile="50"
                        heightMobile="50"
                        alt="mentor-profile-image"
                      />
                    </div>
                    <div class="profile-detail">
                      <p class="profile-name">{{ item.name }},</p>
                      <p class="profile-profession">
                        {{ item.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              v-show="testimoni.items.length > 5"
              class="shadow-arrow shadow-left prev-kata-mereka"
            >
              <Icons
                v-show="testimoni.items.length > 5"
                name="arrow-left"
                class="arrow prev"
              />
            </div>
            <div
              v-show="testimoni.items.length > 5"
              class="shadow-arrow shadow-right next-kata-mereka"
            >
              <Icons
                v-show="testimoni.items.length > 5"
                name="arrow-right"
                class="arrow next"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="faq">
        <div class="container">
          <TitleSection title="PERTANYAAN UMUM" class="kata-mereka-title" />
          <div class="list-faq">
            <div class="accordion" id="accordionPanelsStayOpenExample">
              <div
                class="accordion-item"
                v-for="(faq, index) in FAQ.items"
                :key="'faq-' + index"
              >
                <h2
                  class="accordion-header"
                  :id="'panelsStayOpen-headingOne' + index"
                >
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#panelsStayOpen-collapseOne' + index"
                    :aria-controls="'panelsStayOpen-collapseOne' + index"
                  >
                    <h2 class="title">{{ faq.title }}</h2>
                  </button>
                </h2>
                <div
                  :id="'panelsStayOpen-collapseOne' + index"
                  class="accordion-collapse collapse show"
                  :aria-labelledby="'panelsStayOpen-headingOne' + index"
                >
                  <div class="accordion-body">
                    <div class="desc-wrapper">
                      <p class="desc" v-html="faq.description"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section id="karya-academy">
        <div class="container">
          <TitleSection title="KARYA ACADEMY" class="karya-academy-title" />
          <div class="karya-academy-list">
            <div
              v-swiper:mySwiperKaryaAcademy="swiperOptions.karyaAcademy"

            >
              <div class="swiper-wrapper">
                <ShimmerKenaliMentor v-if="karya_academy.loading" />
                <Link
                  class="karya-academy-card swiper-slide"
                  v-for="item in karya_academy.items"
                  :key="item.id"
                  :to="{
                    name: 'academy-karya-academy-slug',
                    params: {
                      slug: item.slug,
                    },
                  }"
                >
                  <ImageResponsive
                    :imageUrl="item.image"
                    alt="thumbnail-karya-academy"
                  />
                </Link>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-karya-academy"
            >
            <Icons
              name="arrow-left"
              class="arrow prev"
            /></div>
            <div
              class="shadow-arrow shadow-right next-karya-academy"
            >
            <Icons
              name="arrow-right"
              class="arrow next"
            /></div>
          </div>
        </div>
      </section> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import CardTwentyThree from "/components/cards/CardTwentyThree";
import ShimmerTopCarousel from "@/components/shimmer/TopCarousel";
import ShimmerListCard from "@/components/shimmer/ListCard";
import ShimmerKenaliMentor from "@/components/shimmer/KenaliMentor";
import Banner from "@/components/Banner";
import ImageResponsive from "@/components/ImageResponsive";
import TitleSection from "@/components/TitleSection";
import Icons from "@/components/Icons";
import Link from "@/components/Link";

export default {
  async asyncData({ error, store, route }) {
    // error({ statusCode: 404, message: "Page not found" });
    try {
      store.commit("seo/SET_SEO", {
        title: "Academy",
        desc: "Tempat dimana anak muda dapat mengembangkan potensi diri. Daftar sekarang!",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    Link,
    ShimmerKenaliMentor,
    Icons,
    ShimmerListCard,
    TitleSection,
    ImageResponsive,
    Banner,
    CardTwentyThree,
    ShimmerTopCarousel,
  },
  data() {
    return {
      // kelasSaya: {
      //   loading: true,
      //   data: {
      //     data: [
      //       {
      //         id: 1,
      //         progressPercentage: "88",
      //         course: {
      //           active: false,
      //           id: 25,
      //           title: "Jadi Content Creator, Nggak Pakai Repot",
      //           slug: "jadi-content-creator-nggak-pakai-repot",
      //           thumbnail: {
      //             id: 30,
      //             file: null,
      //             type: "image/png",
      //             size: 0,
      //             name: "30 Mei 2022_NA 1920x1080_12.png",
      //             createdAt: 1671072363,
      //             updatedAt: 1671072363,
      //           },
      //           categories: [
      //             {
      //               id: 1,
      //               title: "Journalism",
      //               slug: "journalism",
      //             },
      //           ],
      //           courseLevel: {
      //             id: 1,
      //             title: "Basic",
      //             slug: "basic",
      //           },
      //         },
      //         user: {
      //           id: "071e7e23-976b-4e74-83dd-6bbe4c144a2d",
      //           fullname: "Albi Fajar Ramadhan",
      //           email: "albi@sawala.tech",
      //           phoneNumber: "089691527986",
      //           status: false,
      //         },
      //       },
      //       {
      //         id: 3,
      //         progressPercentage: "20",
      //         course: {
      //           active: false,
      //           id: 39,
      //           title: "Cara Asyik Belajar Jurnalistik",
      //           slug: "cara-asyik-belajar-jurnalistik",
      //           thumbnail: null,
      //           categories: [],
      //           courseLevel: {
      //             id: 1,
      //             title: "Basic",
      //             slug: "basic",
      //           },
      //         },
      //         user: {
      //           id: "071e7e23-976b-4e74-83dd-6bbe4c144a2d",
      //           fullname: "Albi Fajar Ramadhan",
      //           email: "albi@sawala.tech",
      //           phoneNumber: "089691527986",
      //           status: false,
      //         },
      //       },
      //       {
      //         id: 6,
      //         progressPercentage: "100",
      //         course: {
      //           active: false,
      //           id: 38,
      //           title: "Rahasia Storytelling Keren, Bikin Konten Makin Beken",
      //           slug: "rahasia-storytelling-keren-bikin-konten-makin-beken",
      //           thumbnail: null,
      //           categories: [
      //             {
      //               id: 2,
      //               title: "Content Production",
      //               slug: "content-production",
      //             },
      //           ],
      //           courseLevel: {
      //             id: 2,
      //             title: "Intermediate",
      //             slug: "intermediate",
      //           },
      //         },
      //         user: {
      //           id: "071e7e23-976b-4e74-83dd-6bbe4c144a2d",
      //           fullname: "Albi Fajar Ramadhan",
      //           email: "albi@sawala.tech",
      //           phoneNumber: "089691527986",
      //           status: false,
      //         },
      //       },
      //       {
      //         id: 7,
      //         progressPercentage: "100",
      //         course: {
      //           active: false,
      //           id: 37,
      //           title: "KILAT (Kelas Intensif Ilmu Data & Jurnalistik)",
      //           slug: "kilat-kelas-intensif-ilmu-data-and-jurnalistik",
      //           thumbnail: null,
      //           categories: [
      //             {
      //               id: 2,
      //               title: "Content Production",
      //               slug: "content-production",
      //             },
      //           ],
      //           courseLevel: {
      //             id: 2,
      //             title: "Intermediate",
      //             slug: "intermediate",
      //           },
      //         },
      //         user: {
      //           id: "071e7e23-976b-4e74-83dd-6bbe4c144a2d",
      //           fullname: "Albi Fajar Ramadhan",
      //           email: "albi@sawala.tech",
      //           phoneNumber: "089691527986",
      //           status: false,
      //         },
      //       },
      //       {
      //         id: 8,
      //         progressPercentage: "16",
      //         course: {
      //           active: false,
      //           id: 36,
      //           title: "Bikin Video Estetik Tanpa Modal Untuk Investasimu",
      //           slug: "bikin-video-estetik-tanpa-modal-untuk-investasimu",
      //           thumbnail: null,
      //           categories: [
      //             {
      //               id: 2,
      //               title: "Content Production",
      //               slug: "content-production",
      //             },
      //           ],
      //           courseLevel: {
      //             id: 3,
      //             title: "Advanced",
      //             slug: "advance",
      //           },
      //         },
      //         user: {
      //           id: "071e7e23-976b-4e74-83dd-6bbe4c144a2d",
      //           fullname: "Albi Fajar Ramadhan",
      //           email: "albi@sawala.tech",
      //           phoneNumber: "089691527986",
      //           status: false,
      //         },
      //       },
      //       {
      //         id: 9,
      //         progressPercentage: "47",
      //         course: {
      //           active: false,
      //           id: 35,
      //           title:
      //             "Jurnalisme Investigasi Ala Narasi: Sajikan Fakta & Data",
      //           slug: "jurnalisme-investigasi-ala-narasi-sajikan-fakta-and-data",
      //           thumbnail: null,
      //           categories: [
      //             {
      //               id: 1,
      //               title: "Journalism",
      //               slug: "journalism",
      //             },
      //           ],
      //           courseLevel: {
      //             id: 3,
      //             title: "Advanced",
      //             slug: "advance",
      //           },
      //         },
      //         user: {
      //           id: "071e7e23-976b-4e74-83dd-6bbe4c144a2d",
      //           fullname: "Albi Fajar Ramadhan",
      //           email: "albi@sawala.tech",
      //           phoneNumber: "089691527986",
      //           status: false,
      //         },
      //       },
      //     ],
      //     message: "Ok",
      //     status: true,
      //     meta: {
      //       pagination: {
      //         page: 1,
      //         pageSize: 10,
      //         pageCount: 1,
      //         total: 6,
      //       },
      //     },
      //   },
      // },
      swiperOptions: {
        ikuti_kelas: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-ikuti_kelas",
            nextEl: ".next-ikuti_kelas",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            820: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
        masukKelas: {
          freeMode: false,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-masuk-kelas",
            nextEl: ".next-masuk-kelas",
          },
          breakpoints: {
            1023: {
              slidesPerGroup: 2,
              slidesPerView: 4.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerGroup: 2,
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            820: {
              slidesPerGroup: 2,
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              enabled: true,
              slidesPerView: 1.8,
              spaceBetween: 8,
            },
          },
        },
        lanjutkanKelas: {
          freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-lanjutkan-kelas",
            nextEl: ".next-lanjutkan-kelas",
          },
          breakpoints: {
            1023: {
              slidesPerView: 5.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            820: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.1,
              spaceBetween: 8,
            },
          },
        },
        kelasTerdaftar: {
          freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-kelas-terdaftar",
            nextEl: ".next-kelas-terdaftar",
          },
          breakpoints: {
            1023: {
              slidesPerView: 5.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            820: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.1,
              spaceBetween: 8,
            },
          },
        },
        kenaliMentor: {
          freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-kenali-mentor",
            nextEl: ".next-kenali-mentor",
          },
          breakpoints: {
            1023: {
              slidesPerView: 5.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            820: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.5,
              spaceBetween: 8,
            },
          },
        },
        kataMereka: {
          freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-kata-mereka",
            nextEl: ".next-kata-mereka",
          },
          breakpoints: {
            1023: {
              slidesPerView: 5.2,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            820: {
              slidesPerView: 2.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.8,
              spaceBetween: 8,
            },
          },
        },
        karyaAcademy: {
          freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-karya-academy",
            nextEl: ".next-karya-academy",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4.5,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      banner: (state) => {
        return state.academies.academyBanner;
      },
      ikutiKelas: (state) => {
        return state.academies.academyClass;
      },
      mentor: (state) => {
        return state.academies.academyMentor;
      },
      testimoni: (state) => {
        return state.academies.academyTestimoni;
      },
      FAQ: (state) => {
        return state.academies.academyFAQ;
      },
      // karya_academy: (state) => {
      //   return state.academies.academyKarya;
      // },
      // allData: (state) => {
      //   return state.academies.academyAll;
      // },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("academies/getBanner");
      await this.$store.dispatch("academies/getClass");
      await this.$store.dispatch("academies/getMentor");
      await this.$store.dispatch("academies/getTestimoni");
      await this.$store.dispatch("academies/getFAQ", 999);
      // await this.$store.dispatch("academies/getKaryaAcademy");
      // await this.$store.dispatch("academies/getAll");
    },
  },
  mounted() {
    // if (this.$store.state.auth.loggedIn) {
    //   const api = this.$axios.create({
    //     headers: {
    //       // Authorization:
    //       //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiMDcxZTdlMjMtOTc2Yi00ZTc0LTgzZGQtNmJiZTRjMTQ0YTJkIiwiZnVsbG5hbWUiOiJBbGJpIEZhamFyIFJhbWFkaGFuIiwiYmlvZ3JhcGh5IjpudWxsLCJnZW5kZXIiOjIsImJpcnRoZGF0ZSI6IjIwMDEtMTItMjUiLCJjaXR5IjoiU3VtZWRhbmciLCJvY2N1cGF0aW9uIjoiTWFoYXNpc3dhIiwiZW1haWwiOiJhbGJpQHNhd2FsYS50ZWNoIiwicGhvbmVOdW1iZXIiOiIwODk2OTE1Mjc5ODYiLCJzdGF0dXMiOmZhbHNlLCJzdWJzY3JpYmUxME1pbnV0ZXMiOnRydWUsInN1YnNjcmliZVdtbiI6dHJ1ZSwicGljdHVyZSI6Imh0dHBzOi8vc3RvcmFnZS5nb29nbGVhcGlzLmNvbS9zdGF0aWMtbmFyYXNpLXN0YWdpbmcvYXZhdGFyLzAvMDcxZTdlMjMtOTc2Yi00ZTc0LTgzZGQtNmJiZTRjMTQ0YTJkLTE2NzMyNDY5MDcxMDUucG5nIiwiY3JlYXRlZEF0IjoiMjAyMi0xMC0yOVQwNjozNjoyMy43NzVaIiwiY3JlYXRlZEJ5IjpudWxsLCJ1cGRhdGVkQXQiOiIyMDIzLTAxLTA5VDA2OjQ4OjI3Ljg4MloiLCJ1cGRhdGVkQnkiOm51bGwsImRlbGV0ZWRCeSI6bnVsbCwicHJvdmlkZXIiOiJsb2NhbCIsInByaXZhY3kiOnRydWUsImludGVyZXN0cyI6W10sInVuaXZlcnNpdHkiOm51bGx9LCJpYXQiOjE2NzkyMzcwNzksImV4cCI6MTY3OTMyMzQ3OX0.K-NB7wuSqWXJXZmHXwULKWChh5p7tPDxpf14OsYTL7Y",
    //       Authorization: this.$auth.strategy.token.get(),
    //     },
    //   });
    //   api
    //     .$get(`https://narasi-academy-nest.sawala.dev/courses/progress`)
    //     .then((response) => {
    //       // this.kelasSaya = {
    //       //   loading: false,
    //       //   data: response,
    //       // };
    //       this.kelasSaya.loading = false;
    //     })
    //     .catch((e) => {
    //       this.kelasSaya.loading = true;
    //       console.log(e);
    //     });
    // }
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  @media only screen and (max-width: 1024px) {
    padding-bottom: 0 !important;
  }

  #academy-banner {
    padding-top: 24px;
    @media only screen and (max-width: 1024px) {
      padding-top: 0;
      .container {
        padding-right: 0;
        padding-left: 0;
        img {
          width: 100%;
        }
      }
    }
  }

  #academy-content {
    padding: 48px 0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0;
    }

    #about-academy {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        @media only screen and (max-width: 1024px) {
          padding: 0 30px;
        }

        .about-academy-content {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
          }

          .about-academy-detail {
            width: 70%;
            padding-right: 60px;
            @media only screen and (max-width: 1024px) {
              width: 100%;
              padding: 0;
              margin-bottom: 24px;
            }

            .about-academy-title {
              color: #4a25aa;
              font-size: 24px;
              font-weight: 700;
              margin-bottom: 48px;
              @media only screen and (max-width: 1024px) {
                font-size: 20px;
                text-align: center;
                margin-bottom: 24px;
              }
            }

            .about-academy-desc {
              font-size: 14px;
              color: #616161;
              text-align: justify;
              line-height: 25px;
              margin-bottom: 0;
              @media only screen and (max-width: 1024px) {
                text-align: center;
                font-size: 12px;
              }
              span {
                font-weight: 600;
                color: #4a25aa;
              }
            }
          }

          .about-academy-thumbnail {
            width: 30%;
            @media only screen and (max-width: 1024px) {
              width: 100%;
            }
          }
        }
      }
    }

    #ikuti_kelas {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }
      .shadow-arrow {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100px;
        height: 100%;
        padding: 0;
        background: rgb(0, 0, 0);
        z-index: 2;
        opacity: 1;
        transition: ease-in-out 0.5s;
        @media only screen and (max-width: 1024px) {
          display: none;
        }

        svg {
          pointer-events: visible;
        }

        &.shadow-left {
          left: 0;
          background: linear-gradient(
            to left,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1)
          );
        }

        &.shadow-right {
          right: 0;
          background: linear-gradient(
            to right,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1)
          );
        }

        &.swiper-button-disabled {
          opacity: 0;
          z-index: 0;
        }
      }
      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: auto;
        @media only screen and (max-width: 1024px) {
          display: none;
        }

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }

        &.hide {
          display: none;
        }
      }
      .swiper-container {
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }
      }
      .kenali-mentor-title {
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }
      }
    }

    #masuk-kelas {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        .masuk-kelas-title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .swiper-container {
          .swiper-wrapper {
            height: fit-content !important;
          }

          @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            margin: -20px !important;
          }
        }

        .masuk-kelas-list {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }
            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          // display: flex;
          // flex-direction: row;
          // justify-content: space-between;
          // height: fit-content !important;
          // grid-row-gap: 20px;
          // @media only screen and (max-width: 1024px) {
          //   flex-wrap: nowrap;
          //   grid-row-gap: unset;
          // }
          .masuk-kelas-card {
            display: flex;
            padding: 16px;
            border-radius: 10px;
            background: white;
            color: black;
            width: calc(95% / 4);
            @media only screen and (max-width: 1024px) {
              padding: 10px;
              border-radius: 6px;
            }

            .masuk-kelas-thumbnail {
              width: 50%;
              height: 100px;
              padding-right: 20px;
              @media only screen and (max-width: 1024px) {
                width: 40%;
                height: 60px;
                padding-right: 10px;
              }

              .masuk-kelas-image {
                width: auto !important;
                height: 100%;
              }
            }

            .masuk-kelas-card-title {
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                width: 60%;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    #lanjutkan-kelas {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        .lanjutkan-kelas-title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .swiper-container {
          .swiper-wrapper {
            height: fit-content !important;
          }

          @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            margin: -20px !important;
          }
        }

        .lanjutkan-kelas-list {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }
            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .lanjutkan-kelas-thumbnail {
            width: 30%;
            height: initial;
            @media only screen and (max-width: 1024px) {
              width: 40%;
            }
          }

          .lanjutkan-kelas-card {
            display: flex;
            width: 450px;
            height: fit-content;
            border-radius: 10px;
            overflow: hidden;
            background: white;
            @media only screen and (max-width: 1024px) {
              border-radius: 6px;
            }

            .lanjutkan-kelas-card-content {
              width: 70%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: relative;
              @media only screen and (max-width: 1024px) {
                width: 60%;
              }

              .copy-wrapper {
                padding: 15px;
                flex: 95%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media only screen and (max-width: 1024px) {
                  padding: 12px;
                }
                .top {
                  .lanjutkan-kelas-card-kategori {
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 5px;
                    color: #4a25aa;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                  .lanjutkan-kelas-card-title {
                    font-size: 16px;
                    font-weight: 500;
                    color: black;
                    margin-bottom: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    @media only screen and (max-width: 1024px) {
                      font-size: 14px;
                    }
                  }
                }
                .bottom {
                  .lanjutkan-kelas-card-total-materi {
                    font-size: 12px;
                    color: #616161;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                }
              }

              .progress {
                flex: 5%;
                background: #e0e0e0 !important;
                .progress-bar {
                  background-color: #ffe900 !important;
                }
              }
            }
          }
        }
      }
    }

    #kelas-terdaftar {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        .kelas-terdaftar-title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .kelas-terdaftar-list {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }
            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            .swiper-wrapper {
              height: fit-content !important;
            }

            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }

          .kelas-terdaftar-card {
            flex-direction: column;
            padding: 15px;
            align-items: center;
            width: 450px;
            height: fit-content;
            border-radius: 10px;
            overflow: hidden;
            background: white;
            @media only screen and (max-width: 1024px) {
              border-radius: 6px;
            }

            .kelas-terdaftar-card-header {
              display: flex;
              align-items: center;

              .kelas-terdaftar-card-header-content {
                width: 65%;

                .kelas-terdaftar-card-kategori {
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 8px;
                  color: #4a25aa;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                  }
                }

                .kelas-terdaftar-card-title {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 2;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                  }
                }
              }

              .kelas-terdaftar-card-btn-wrapper {
                width: 35%;
                text-align: right;

                .kelas-terdaftar-card-btn-daftar {
                  font-size: 12px;
                  color: white;
                  text-decoration: none;
                  background: #4a25aa;
                  padding: 5px 10px;
                  border-radius: 5px;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }

                  &:hover {
                    color: white !important;
                  }
                }
              }
            }

            .kelas-terdaftar-card-body {
              display: flex;

              .kelas-terdaftar-card-thumbnail {
                width: 40%;
                border-radius: 10px;
                overflow: hidden;
                @media only screen and (max-width: 1024px) {
                  border-radius: 6px;
                }
              }

              .kelas-terdaftar-card-body-content {
                width: 60%;
                padding-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media only screen and (max-width: 1024px) {
                  padding-left: 12px;
                }

                .box {
                  display: flex;
                  align-items: center;

                  svg {
                    margin-right: 5px;
                    width: 18px;
                    height: 18px;
                  }

                  .text {
                    font-size: 12px;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    #kenali-mentor {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        .kenali-mentor-title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .kenali-mentor-list {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }
            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            .swiper-wrapper {
              height: fit-content !important;
            }

            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }

          .kenali-mentor-card {
            flex-direction: column;
            align-items: center;
            width: 250px;
            height: fit-content;
            border-radius: 10px;
            overflow: hidden;
            background: white;
            @media only screen and (max-width: 1024px) {
              border-radius: 6px;
            }

            .image {
              height: 200px;
              ::v-deep img {
                object-fit: cover;
              }
            }

            .kenali-mentor-card-content {
              padding: 16px;
              height: 102px;

              .kenali-mentor-card-name {
                font-size: 16px;
                font-weight: 500;
                color: #211f26;
                margin-bottom: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                pointer-events: none;

                &:hover {
                  color: #211f26 !important;
                }
              }

              .kenali-mentor-card-profession {
                font-size: 12px;
                font-weight: 400;
                color: #616161;
                margin: 0;
              }
            }
          }
        }
      }
    }

    #kata-mereka {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        .kata-mereka-title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .kata-mereka-list {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }
            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            .swiper-wrapper {
              height: fit-content !important;
            }

            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }

          .kata-mereka-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 250px;
            height: 300px;
            padding: 24px 15px;
            border-radius: 10px;
            overflow: hidden;
            background: white;
            @media only screen and (max-width: 1024px) {
              border-radius: 6px;
              padding: 16px 12px;
            }
            .copy {
              .copy-title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }

              .copy-desc {
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 5;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1024px) {
                  font-size: 10px;
                }
              }
            }

            .profile {
              display: flex;

              .profile-image {
                width: 25%;
                align-self: center;

                .image {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  @media only screen and (max-width: 1024px) {
                    width: 40px;
                    height: 40px;
                  }
                }
              }

              .profile-detail {
                width: 75%;
                padding-left: 15px;
                align-self: center;

                .profile-name {
                  font-size: 12px;
                  font-weight: 500;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }

                .profile-profession {
                  font-size: 12px;
                  font-weight: 400;
                  margin: 0;
                  @media only screen and (max-width: 1024px) {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }

    #faq {
      .container {
        .title {
          margin-bottom: 24px;
        }
        .list-faq {
          margin-bottom: 24px;
          .accordion {
            .accordion-item {
              margin-bottom: 12px;
              border-radius: 6px;
              overflow: hidden;
              @media only screen and (max-width: 1024px) {
                margin-bottom: 12px;
              }
              &:last-child {
                margin: 0;
              }
              .accordion-header {
                .accordion-button {
                  background: #ffffff;
                  box-shadow: none;
                  &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L10 10.5858L6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289Z' fill='%23424242'/%3E%3C/svg%3E%0A");
                    background-size: 25px;
                    width: 25px;
                    height: 25px;
                    @media only screen and (max-width: 1024px) {
                      background-size: 20px;
                      width: 20px;
                      height: 20px;
                    }
                  }
                  .title {
                    font-size: 20px;
                    font-weight: 600;
                    color: #616161;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 16px;
                    }
                  }
                }
              }
              .accordion-collapse {
                .accordion-body {
                  @media only screen and (max-width: 1024px) {
                    padding-top: 0;
                  }
                  .desc-wrapper {
                    .desc {
                      font-size: 18px;
                      color: #616161;
                      @media only screen and (max-width: 1024px) {
                        font-size: 12px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .goto-detail-faq {
          .title {
            font-size: 32px;
            color: #0c0b0d;
            text-align: center;
            margin-bottom: 16px;
            @media only screen and (max-width: 1024px) {
              font-size: 18px;
              margin-bottom: 8px;
            }
          }
          .btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            .btn-see-more {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
              &:hover {
                color: #4a25aa !important;
              }
            }
            svg {
              width: 12px;
              height: auto;
              margin-left: 12px;
              @media only screen and (max-width: 1024px) {
                width: 8px;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }

    #karya-academy {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .container {
        .karya-academy-title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .karya-academy-list {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }
            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            .swiper-wrapper {
              height: fit-content !important;
            }

            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }
  }
}
</style>
